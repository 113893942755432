// import { myStore } from "@/store/pinia-store.ts";
// const store = myStore();
module.exports = [{
    "":  {
        "META_TITLE": "Zulutrade | The leading social copy trading platform.Trade Forex, Stocks, Crypto",
        "META_DESCRIPTION": "With Zulutrade you can trade forex, commodities, indices, stock and crypto. Trade manually or through copy trading. Create a demo or live account today.",
        "META_KEYWORDS": "",
    },

    "leaders":  {
        "META_TITLE": "ZuluTrade - Find the Best Traders to Follow and Copy",
        "META_DESCRIPTION": "Discover the top-performing traders on ZuluTrade and start copying their trades to make profits. Choose from a wide range of strategies.",
        "META_KEYWORDS": "",
    },
    "markets":  {
        "META_TITLE": "ZuluTrade - Trade the Financial markets with the Best Social Trading Platform",
        "META_DESCRIPTION": "Explore the financial markets and trade with the best social copy trading platform. Follow and copy the trades of top-performing traders  to maximize your profits. ",
        "META_KEYWORDS": "",
    },
    // "`/trader/${store.traderDetail.trader.stats.providerId}/trading`":  {
    //     "META_TITLE": "ZuluTrade Trader {TRADER_NAME} Trading Statistics & Performance",
    //     "META_DESCRIPTION": "Explore the trading statistics and performance of ZuluTrade trader  {TRADER_NAME}. Discover their profit, drawdown, and trading history.",
    //     "META_KEYWORDS": "",
    // },
    "help-center":  {
        "META_TITLE": "ZuluTrade - Help Center",
        "META_DESCRIPTION": "Get answers to your questions about ZuluTrade and social trading. Browse our knowledge base, read our guides and tutorials, and contact our support team for assistance.",
        "META_KEYWORDS": "",
    },
    "user-guide":  {
        "META_TITLE": "ZuluTrade - User Guide",
        "META_DESCRIPTION": "Learn how to use ZuluTrade and social trading to reach your goals. Our user guide covers everything you need to know, from account setup to copying trades and managing risk.",
        "META_KEYWORDS": "",
    },
    "leader-guide":  {
        "META_TITLE": "ZuluTrade - Leader Guide",
        "META_DESCRIPTION": "Become a ZuluTrade leader and earn by sharing your trades. Our leader guide covers everything you need to know, from account setup to managing followers and earning commissions.",
        "META_KEYWORDS": "",
    },
    "affiliate-guide":  {
        "META_TITLE": "ZuluTrade Affiliate Program Guide | Promotional guidelines and more",
        "META_DESCRIPTION": "ZuluTrade's Affiliate program guide explain to Affiliates and Partners in more details all aspects of the Affiliate program, including what is allowed and what is not!",
        "META_KEYWORDS": "",
    },
    "simulation":  {
        "META_TITLE": "ZuluTrade - Simulation",
        "META_DESCRIPTION": "Simulate trading ideas and test your strategies with ZuluTrade's simulation feature. Trade with virtual money and get real-time market data to improve your trading skills.",
        "META_KEYWORDS": "",
    },
    "calendar":  {
        "META_TITLE": "ZuluTrade - Economic Calendar",
        "META_DESCRIPTION": "Stay up to date with the latest economic events and market news with ZuluTrade's economic calendar. Get updates on market-moving events and make informed trading decisions.",
        "META_KEYWORDS": "",
    },
    "trading-tools":  {
        "META_TITLE": "ZuluTrade - Trading Tools",
        "META_DESCRIPTION": "Maximize your profits and manage your risk with ZuluTrade's trading tools. Use our calculators to make informed trading decisions.",
        "META_KEYWORDS": "",
    },
    "trading-tools/pip-calculator":  {
        "META_TITLE": "ZuluTrade - Pip Calculator",
        "META_DESCRIPTION": "Calculate the value of a pip for various currency pairs and position sizes with ZuluTrade's pip calculator. Use the calculator to manage your risk and maximize your profits.",
        "META_KEYWORDS": "",
    },
    "trading-tools/margin-calculator":  {
        "META_TITLE": "ZuluTrade - Margin Calculator",
        "META_DESCRIPTION": "Calculate the margin required for various currency pairs and position sizes with ZuluTrade's margin calculator. Mmanage your risk and make informed trading decisions.",
        "META_KEYWORDS": "",
    },
    "trading-tools/profit-calculator":  {
        "META_TITLE": "ZuluTrade - Profit Calculator",
        "META_DESCRIPTION": "Calculate your potential profits and losses for various currency pairs and position sizes with ZuluTrade's profit calculator. Use the calculator to manage",
        "META_KEYWORDS": "",
    },
    "trading-tools/currency-calculator":  {
        "META_TITLE": "ZuluTrade - Forex Currency Calculator",
        "META_DESCRIPTION": "Calculate currency exchange rates with the ZuluTrade Forex calculator. Easily convert over 50+ currency pairs.",
        "META_KEYWORDS": "",
    },
    "about":  {
        "META_TITLE": "About ZuluTrade",
        "META_DESCRIPTION": "Learn about ZuluTrade, the leading social copy trading platform that connects traders from all around the world.",
        "META_KEYWORDS": "",
    },
    "news-and-media":  {
        "META_TITLE": "ZuluTrade News and Media",
        "META_DESCRIPTION": "Stay up-to-date with the latest news and media coverage about ZuluTrade, the world's leading social copy trading platform.",
        "META_KEYWORDS": "",
    },
    "become-an-affiliate":  {
        "META_TITLE": "ZuluTrade Affiliate Program | Join the Fintech Era. Be our Brand's Partner",
        "META_DESCRIPTION": "ZuluTrade Affiliate program allows to website & community managers, influencers and any content creator to monetize their audience reach. Join the Fintech Era!",
        "META_KEYWORDS": "",
    },
    "become-a-leader":  {
        "META_TITLE": "Become a ZuluTrade Leader",
        "META_DESCRIPTION": "Join the ZuluTrade trading community and become a leader. Earn money by sharing your trading skills with others.",
        "META_KEYWORDS": "",
    },
    "partnership-program":  {
        "META_TITLE": "ZuluTrade Partnership Program",
        "META_DESCRIPTION": "Contact us to discuss a partnership and enjoy the benefits of partnering with the world's leading social trading platform.",
        "META_KEYWORDS": "",
    },
    "terms-of-service":  {
        "META_TITLE": "ZuluTrade Terms of Service",
        "META_DESCRIPTION": "Read the ZuluTrade terms of service and understand the legal agreements between you and ZuluTrade.",
        "META_KEYWORDS": "",
    },
    "privacy-policy":  {
        "META_TITLE": "ZuluTrade Privacy Policy",
        "META_DESCRIPTION": "Read the ZuluTrade privacy policy to understand how we collect, use, and protect your personal information.",
        "META_KEYWORDS": "",
    },
    "risk-disclaimer":  {
        "META_TITLE": "ZuluTrade Risk Disclaimer",
        "META_DESCRIPTION": "Trading foreign exchange carries a high level of risk and may not be suitable for all investors. Read the ZuluTrade risk disclaimer carefully.",
        "META_KEYWORDS": "",
    },
    "conflict-of-interest-policy":  {
        "META_TITLE": "ZuluTrade Conflict of Interest Policy",
        "META_DESCRIPTION": "Read the ZuluTrade conflict of interest policy to understand how we manage conflicts of interest between our clients and our business.",
        "META_KEYWORDS": "",
    },
    "declaration-of-consent":  {
        "META_TITLE": "ZuluTrade Declaration of Consent",
        "META_DESCRIPTION": "Read and accept the ZuluTrade declaration of consent to start using our social trading platform.",
        "META_KEYWORDS": "",
    },
    "autoprotect-your-account":  {
        "META_TITLE": "ZuluTrade ZuluGuard",
        "META_DESCRIPTION": "Protect your trading account with ZuluTrade ZuluGuard. Limit your risk and maximize your profit potential.",
        "META_KEYWORDS": "",
    },
    "login":  {
        "META_TITLE": "ZuluTrade - Login to your Account | Trade with the Best Social Trading Platform",
        "META_DESCRIPTION": "Log in to your ZuluTrade account and trade with the best social trading platform. Follow and copy the trades of top-performing traders and make profits. ",
        "META_KEYWORDS": "",
    },
    "zulutrade-data":  {
        "META_TITLE": "ZuluTrade Data - Analytics and Insights",
        "META_DESCRIPTION": "Get tradingdata, insights, and analytics from ZuluTrade, the leading social coly trading platform",
        "META_KEYWORDS": "",
    },
    "settings":  {
        "META_TITLE": "Settings - Customize Your Trading Experience",
        "META_DESCRIPTION": "Customize your ZuluTrade trading experience with advanced settings and tools",
        "META_KEYWORDS": "",
    },
    "forex-trading":  {
        "META_TITLE": "Forex Trading - Trade the Global financial markets and Currencies with ZuluTrade",
        "META_DESCRIPTION": "Trade forex with ZuluTrade and access a global network of expert traders to copy their trading signals",
        "META_KEYWORDS": "",
    },
    "stock-trading":  {
        "META_TITLE": "Stock Trading - Invest in Leading Global Companies, trade stocks in ZuluTrade",
        "META_DESCRIPTION": "Invest in leading global companies with ZuluTrade's stock copy trading platform",
        "META_KEYWORDS": "",
    },
    "cryptocurrency-trading":  {
        "META_TITLE": "Crypto Trading - Trade the Future of currencies",
        "META_DESCRIPTION": "Trade the future with ZuluTrade's crypto copy trading platform",
        "META_KEYWORDS": "",
    },
    "commodities-trading":  {
        "META_TITLE": "Commodities Trading - Diversify Your Portfolio",
        "META_DESCRIPTION": "Trade gold, oil, and other commodities like a pro by following and copying the trades of successful traders with ZuluTrade. Join our social trading community.",
        "META_KEYWORDS": "",
    },
    "index-trading":  {
        "META_TITLE": "Index Trading with ZuluTrade - Follow and Copy Top Traders",
        "META_DESCRIPTION": "Trade stock indices like the NAS 100,  SPX500 and more by following and copying the trades of successful traders with ZuluTrade. Join our social trading community",
        "META_KEYWORDS": "",
    },
    "automated-trading":  {
        "META_TITLE": "Automated Trading with ZuluTrade - Follow and Copy Top Traders",
        "META_DESCRIPTION": "Automate your trading by following and copying the trades of successful traders with ZuluTrade. Sign up for free on a demo account and experience the future of online trading.",
        "META_KEYWORDS": "",
    },
    "newsfeed/share":  {
        "META_TITLE": "ZuluTrade - Newsfeed",
        "META_DESCRIPTION": "Share your newsfeed and  Stay up-to-date with the latest news and market insights on ZuluTrade. Share your thoughts and opinions with the community. Join now!",
        "META_KEYWORDS": "",
    },
    "compare":  {
        "META_TITLE": "ZuluTrade - Compare trading strategies and Trading systems",
        "META_DESCRIPTION": "Compare the performance of different traders on ZuluTrade. Choose the best ones to copy and start earning profits with social trading. Join now!",
        "META_KEYWORDS": "",
    },
    "tutorials":  {
        "META_TITLE": "ZuluTrade - Tutorials",
        "META_DESCRIPTION": "Learn how to use ZuluTrade with our easy-to-follow tutorials. Start copying the trades of professional traders and earn profits with social copy trading. Join now!",
        "META_KEYWORDS": "",
    },
    "disclaimer":  {
        "META_TITLE": "ZuluTrade - Disclaimer",
        "META_DESCRIPTION": "Read our disclaimer before using ZuluTrade. Trading involves risks and you should be aware of them before investing your money.",
        "META_KEYWORDS": "",
    },
    "help-center-questions":  {
        "META_TITLE": "ZuluTrade - Help Center",
        "META_DESCRIPTION": "Find answers to your questions and get support from our team. Our help center is here to assist you in using ZuluTrade. Join now!",
        "META_KEYWORDS": "",
    },
    "zuluranking":  {
        "META_TITLE": "ZuluTrade - ZuluRanking",
        "META_DESCRIPTION": "See the top-ranked traders on ZuluTrade based on their performance. Copy their trades and start earning profits with social copy trading. Join now!",
        "META_KEYWORDS": "",
    },
    "terms-of-service-eu":  {
        "META_TITLE": "ZuluTrade - Terms of Service for EU clients",
        "META_DESCRIPTION": "Read our terms of service before using ZuluTrade. By using our platform, you agree to abide by these terms.",
        "META_KEYWORDS": "",
    },
    "copy-trading":  {
        "META_TITLE": "Copy Trade With the #1 Copy Trading Platform | ZuluTrade",
        "META_DESCRIPTION": "Copy trade with #1 copytrader, Steps to start copytrading. Trade the markets with ZuluTrade copy trader platform and copy trading software (AKA social trading).",
        "META_KEYWORDS": "",
    },
    "not-found":  {
        "META_TITLE": "ZuluTrade - Page Not Found",
        "META_DESCRIPTION": "Oops! The page you were looking for does not exist. Please check the URL or go to our homepage to find what you are looking for.",
        "META_KEYWORDS": "",
    },
    "open-new-investror-account":  {
        "META_TITLE": "ZuluTrade - Open a New Investor Account",
        "META_DESCRIPTION": "Open a new investor account on ZuluTrade and start copying the trades of professional traders. Join now and larn experience with social trading!",
        "META_KEYWORDS": "",
    },
    "printable-terms":  {
        "META_TITLE": "ZuluTrade - Printable Terms",
        "META_DESCRIPTION": "Download and print our terms of service for your records. By using ZuluTrade, you agree to abide by these terms.",
        "META_KEYWORDS": "",
    },
    "signup-activation":  {
        "META_TITLE": "ZuluTrade - Sign Up Activation",
        "META_DESCRIPTION": "Activate your ZuluTrade account and start copying the trades of professional traders.",
        "META_KEYWORDS": "",
    },
    "social-trading":  {
        "META_TITLE": "ZuluTrade - Social Trading",
        "META_DESCRIPTION": "Social trading allows you to exchange ideas between a community and copy the trades of professional traders via copytrading",
        "META_KEYWORDS": "",
    },
    "page-not-found":  {
        "META_TITLE": "404 Error - Page Not Found",
        "META_DESCRIPTION": "Sorry, the page you are looking for could not be found. Please check the URL or return to the homepage.",
        "META_KEYWORDS": "",
    },
    "payment/success":  {
        "META_TITLE": "Payment Successful - Thank You for Choosing ZuluTrade",
        "META_DESCRIPTION": "Your payment has been successfully processed. Thank you for choosing ZuluTrade as your trading platform.",
        "META_KEYWORDS": "",
    },
    "payment/failure":  {
        "META_TITLE": "Payment Failed",
        "META_DESCRIPTION": "Sorry, your payment could not be processed. Please check your payment information. If you continue to experience problems, please contact our customer support team for assistance.",
        "META_KEYWORDS": "",
    },
    "rewards":  {
        "META_TITLE": "ZuluTrade Rewards Program - Earn While You Trade",
        "META_DESCRIPTION": "Join the ZuluTrade Rewards Program and earn rewards for every uswr yoou reffer to us.",
        "META_KEYWORDS": "",
    },
    "notifications":  {
        "META_TITLE": "Stay Up-to-Date with ZuluTrade Notifications",
        "META_DESCRIPTION": "Receive real-time notifications about your trades, top traders, and market events with ZuluTrade. Stay on top of your trading with our advanced notification system.",
        "META_KEYWORDS": "",
    },
    "watchlist":  {
        "META_TITLE": "Create Your Watchlist with ZuluTrade",
        "META_DESCRIPTION": "Stay on top of the markets by creating your own personalized watchlist with ZuluTrade. Follow the traders and instruments that interest you the most.",
        "META_KEYWORDS": "",
    },
    "dashboard":  {
        "META_TITLE": "Your Trading Dashboard with ZuluTrade",
        "META_DESCRIPTION": "Stay informed about your trading with ZuluTrade's advanced dashboard. Monitor your performance, follow top traders, and access our suite of trading tools.",
        "META_KEYWORDS": "",
    },
    "zuluguard-guide":  {
        "META_TITLE": "Protect Your Trading with ZuluGuard",
        "META_DESCRIPTION": "Learn how ZuluGuard can help protect your trading from unexpected losses. ZuluGuard is a powerful risk management tool that can automatically close trades to protect your account.",
        "META_KEYWORDS": "",
    },
}]