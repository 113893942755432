import { createStore } from 'vuex';
// import { Const } from './const'
// import * as Enpoints from '../../../../store/api/endpoints'
export default createStore({
    state: {},
    mutations: {},
    actions: {
    /*[Const.RUN_GET_CHART_DATA]({ commit, state, getters, dispatch }, credentials) {
        COMMON._executeApi(credentials.localParams.link, credentials, { 'Authorization': 'Bearer ' + getters.GET_TOKEN}, commit, (error, result) => {
            if (!error && Array.isArray(result.res_data)) {
                if(credentials.localParams.type == 'day'){
                    credentials.localParams.parameter.oneDayData = result.res_data[0] || {}
                }
                if(credentials.localParams.type == 'year'){
                    let high=0,low=0;
                    if(result.res_data.length){
                        result.res_data.forEach(val=>{
                            if(high< val.high){ high = val.high; }
                            if(low == 0 || low > val.low){ low = val.low; }
                        })
                    }
                    credentials.localParams.parameter.prevYearData = {high,low}
                }
                // commit(Const.SET_ACTIVE_PRICE_ALERT, result.res_data.sort((a,b)=>  b.AlertID - a.AlertID) )
            } else {
                // COMMON._messageDisplayFunction(credentials.localParams.parameter, result.res_data, 'error')
            }
        })
    },*/
    },
    modules: {}
});
/*function _executeApi(apiLink, credentials, getToken, commit, callback) {
    try {
        let response_array = []
        if (credentials.localParams.type == 'form') { credentials.globalParams = credentials.globalParams.form }
        if (getToken == '') { getToken = '' }
        credentials.localParams.parameter.pageLoader = true

        RxFactory.post(Enpoints.BASE_URL + apiLink, credentials.globalParams, getToken)
            .map(response => response.data)
            .subscribe(data => {
                    credentials.localParams.parameter.pageLoader = false
                    if ('info' in data) {
                        if (data.info === 'OK') {
                            response_array.res_data = data.data
                            callback(false, response_array)
                        } else {
                            if (typeof data.msg == 'object') {
                                response_array.res_data = data.msg.msg
                            } else {
                                response_array.res_data = data.msg
                            }
                            callback(true, response_array)
                        }
                    }
                },
                e => { _handleErrorResponse(e, commit) })
    } catch (ex) { _handleErrorResponse(ex, commit) }
}*/ 
