<template>
    <div class="modal giftModal d-block">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="position-absolute radius20 giftHeader border-0">
                    <button type="button" class="btn-close giftClose" @click="$parent.closeGift()"></button>
                </div>
                <div class="modal-body">
                    <div class="d-flex align-items-center">
                        <img class="me-2 flex-shrink-0" width="120" src="/assets/images/gift.png">
                        <div class="content">
                            <h3 class="mb-3">{{$t('changes.text6')}}</h3>
                            <p>{{$t('changes.text7')}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default{
        data(){
            return {}
        }
    }
</script>