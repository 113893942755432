<style scoped>
.loadermore {
    width: 44px;
    height: 44px;
    border: 5px solid #ff7200;
    border-bottom-color: #ccc;
    border-radius: 50%;
    display: inline-block;
    animation: rotation 1s linear infinite;
}
@keyframes rotation {
    0% { transform: rotate(0deg) }
    100% { transform: rotate(360deg) }
}
</style>
<template>
  <div class="d-flex justify-content-center">
      <span class="loadermore"></span>
  </div>
</template>
