export const GLOBAL_BASE_URL = process.env.VUE_APP_API_BASE_URL
// export const AUTH_BASE_URL = process.env.VUE_APP_AUTH_BASE_URL
export const AUTH_BASE_URL =  (`${process.env.VUE_APP_MODE}` == "'development'" || `${process.env.VUE_APP_MODE}` == 'development')? `${process.env.VUE_APP_AUTH_BASE_URL}`:`https://${window.location.host}`
//===========================================store function api=========================================
export const EP_USER_LOGIN = `${AUTH_BASE_URL}/zulutrade-client/auth-server/oauth/token`
export const EP_REGISTRATION = `${AUTH_BASE_URL}/zulutrade-client/api/customer/register`
export const EP_GET_ALL_BROKERS_LIST = `${AUTH_BASE_URL}/zulutrade-client/brokers/api/brokers/available`
export const EP_GET_FLAVOR_LIST = `${AUTH_BASE_URL}/zulutrade-client/flavors/api/flavors/detect/light`
export const EP_GET_LOGIN_FLAVOR_LIST = `${AUTH_BASE_URL}/zulutrade-client/flavors/api/flavors/light/secure`

//leader API old
export const EP_GET_TRADER_DETAIL = `${GLOBAL_BASE_URL}/zulutrade-client/v2/api/providers/{{TradeID}}/thi/init?accessingFlavorId={{flavorId}}&flavor={{region}}`
export const EP_GET_TRADER_DETAIL_SECURED = `${GLOBAL_BASE_URL}/zulutrade-client/v2/api/user/providers/{{TradeID}}/thi/init?accessingFlavorId={{flavorId}}&flavor={{region}}`
export const EP_GET_TOP_TRADER_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/providers/performance/topTraders/search?flavorId={{flavorId}}&accessingFlavorId={{accessingFlavorId}}`


export const EP_GET_FILTERED_ALL_TRADER_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/providers/performance/topTraders/{{ID}}/search?flavorId=1&accessingFlavorId=1`
export const EP_GET_SEARCH_TRADER_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/search?accessingFlavorId={{accessingFlavorId}}&limit=5&name={{name}}&flavor={{flavor}}`
export const EP_GET_COMBOS_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/providers/performance/topCombos/search?flavorId=1`
export const EP_GET_MARKETS_SEARCH = `${GLOBAL_BASE_URL}/statsservice/api/markets/search`
export const EP_GET_NEWSFEED_LIST = `${GLOBAL_BASE_URL}/api/user/newsfeed`
export const EP_GET_CALCULATE_RATES = `${GLOBAL_BASE_URL}/Pages/FxTools.aspx/CalculateRates`
export const EP_GET_TOP_FIVE_FOLLOWERS_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/traders/api/providers/{{TradeID}}/top5livefollowers`
export const EP_GET_FOLLOWERS_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/traders/api/providers/{{TradeID}}/livefollowers?page={{page}}&size=10`
export const EP_GET_TRADE_HISTORY_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/providers/{{TradeID}}/trades/history?timeframe={{timeframe}}&page={{page}}&size={{size}}&sort={{sort}}`
export const EP_GET_TRADE_HISTORY_CURRENCY_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/providers/{{TradeID}}/trades/history?timeframe={{timeframe}}&page={{page}}&size={{size}}&sort={{sort}}&id={{id}}`
export const EP_GET_OPEN_POSITION_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/providers/{{TradeID}}/trades/open/all?timeframe=10000&calculateProfit=true`
export const EP_GET_CALCULATE_PIP = `${GLOBAL_BASE_URL}/zulutrade-client/utils-service/api/forex/calculators/pip?currency={{currency}}&lot={{lot}}&lotSize={{lotSize}}&pips={{pips}}&baseCurrency={{baseCurrency}}`
export const EP_GET_CALCULATE_MARGIN = `${GLOBAL_BASE_URL}/zulutrade-client/utils-service/api/forex/calculators/margin?currency={{currency}}&lotSize={{lotSize}}&leverage={{leverage}}&baseCurrency={{baseCurrency}}`
export const EP_GET_CALCULATE_PROFIT = `${GLOBAL_BASE_URL}/zulutrade-client/utils-service/api/forex/calculators/profit?currency={{currency}}&lot={{lot}}&lotSize={{lotSize}}&openPrice={{openPrice}}&closePrice={{closePrice}}&baseCurrency={{baseCurrency}}`
export const EP_GET_CONVERT_CURRENCY = `${GLOBAL_BASE_URL}/zulutrade-client/utils-service/api/converters/currency?amount={{amount}}&currencyFrom={{currencyFrom}}&currencyTo={{currencyTo}}`
export const EP_GET_CONVERTER_CURRENCY = `${GLOBAL_BASE_URL}/zulutrade-client/utils/api/tools/converterCurrencies`
export const EP_GET_PIPS_CURRENCY = `${GLOBAL_BASE_URL}/zulutrade-client/utils/api/tools/calculatorCurrencies`
export const EP_GET_BASECURRENCY = `${GLOBAL_BASE_URL}/zulutrade-client/utils-service/api/tools/calculatorBaseCurrencies`
export const EP_GET_CURRENCY_PAIRS_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/utils/api/currencies/details?feedGroupId={{feedGroupId}}`
export const EP_GET_TRADING_PROFIT_CHART_DATA = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/{{TradeID}}/performance/chart?type={{type}}&timespan=DAILY&timeframe={{timeframe}}`
export const EP_GET_TRADING_PROFIT_CHART_DATA_WITH_CURRENCY = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/{{TradeID}}/performance/chart?type={{type}}&timespan=DAILY&timeframe={{timeframe}}&cid={{cid}}`
export const EP_GET_TRADING_PERFORMANCE_CHART_DATA = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/{{TradeID}}/performance/chart?type={{type}}&timespan={{timespan}}&timeframe={{timeframe}}`
export const EP_GET_TRADING_PERFORMANCE_RANKING_CHART_DATA = `${GLOBAL_BASE_URL}//zulutrade-client/traderstats/v2/api/providers/{{TradeID}}/performance/chart?type=RANKING&timespan=&timeframe={{timeframe}}&flavor=global`
export const EP_GET_CHART_DATA = `${GLOBAL_BASE_URL}//zulutrade-client/traderstats/v2/api/providers/{{TradeID}}/performance/chart?`

/*** Social Start ***/
export const EP_GET_SOCIAL_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/newsfeed/provider/{{TradeID}}?size=10`
// export const EP_GET_SOCIAL_AUTOLOAD_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/newsfeed/provider/{{TradeID}}?size=10&eventId={{EventID}}`
export const EP_GET_SOCIAL_LOADMORE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/newsfeed/provider/{{TradeID}}?size=10&eventId={{EventID}}`
export const EP_GET_SOCIAL_TYPE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/traders/{{TradeID}}/events?type=STATUS_UPDATE`
export const EP_GET_SOCIAL_TYPE_COMMENT_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/user/newsfeed/{{ZuluEventUD}}`
export const EP_GET_SOCIAL_COMMENT_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/user/newsfeed/{{ZuluEventUD}}`
export const EP_GET_SOCIAL_HASTAG_RIGHT_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/user/{{ZuluID}}/hashtags?size=10`
export const EP_GET_SOCIAL_HASTAG_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/user/newsfeed?size=10&hashtag={{Hastag}}`
export const EP_GET_SOCIAL_HASTAG_LOADMORE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/newsfeed/provider/{{TradeID}}?size=10&eventId={{EventID}}&hashtagToFilter={{Hastag}}`
// export const EP_POST_SOCIAL_TOP_COMMENT_SIMPLE = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/events/save`
export const EP_POST_SOCIAL_TOP_COMMENT = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/v2/api/events/save`
export const EP_SOCIAL_FEED_REPORT = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/events/save`
export const EP_GET_SOCIAL_TOP_COMMENT_PUBLIC_NAME = `${GLOBAL_BASE_URL}/zulutrade-client/api/newsfeed/user/search?type=PUBLIC_NAME&searchTerm={{publicName}}`
export const EP_GET_SOCIAL_TOP_COMMENT_HASHTAG = `${GLOBAL_BASE_URL}/zulutrade-client/api/newsfeed/user/search?type=HASHTAG&searchTerm={{hastagName}}`
export const EP_GET_SOCIAL_LIVE_FOLLOWERS_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/traders/api/providers/{{TradeID}}/livefollowers?page={{page}}&size=10`
export const EP_GET_SOCIAL_FEED_PAGE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/user/newsfeed?size=10`
export const EP_GET_SOCIAL_FEED_PAGE_LOADMORE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/user/newsfeed?size=10&eventId={{EventID}}`
export const EP_GET_MY_FEED_PAGE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/customer/newsfeed?size=10&own=true`
export const EP_GET_MY_FEED_PAGE_LOADMORE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/customer/newsfeed?size=10&own=true&eventId={{EventID}}`
export const EP_GET_MY_PAGE_HASTAG_LOADMORE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/customer/newsfeed?size=10&own=true&eventId={{EventID}}&hashtag={{Hastag}}`
export const EP_GET_SOCIAL_HASTAG_MY_FEED_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/customer/newsfeed?size=10&hashtag={{Hastag}}`

export const EP_GET_SOCIAL_FEED_PAGE_HASTAG_RIGHT_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/hashtags?size=10`
export const EP_GET_SOCIAL_FEED_PAGE_HASTAG_LOADMORE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/user/newsfeed?size=10&eventId={{EventID}}&hashtag={{Hastag}}`
export const EP_GET_SOCIAL_LIKE = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/newsfeed/{{SocialEventID}}/{{status}}`
export const EP_GET_SOCIAL_DELETE = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/newsfeed/{{SocialEventID}}/comment`
export const EP_POST_SOCIAL_COMMENT = `${GLOBAL_BASE_URL}/zulutrade-gateway/socialevents/v2/api/newsfeed/{{SocialEventID}}/comment`
export const EP_POST_SOCIAL_COMMENT_EDIT = `${GLOBAL_BASE_URL}/zulutrade-gateway/socialevents/v2/api/newsfeed/{{SocialEventID}}/comment`
export const EP_GET_SOCIAL_FEED_MY_HASTAGS = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/user/hashtags?size=100`
export const EP_GET_SOCIAL_FEED_HASTAG_FOLLOW = `${GLOBAL_BASE_URL}/zulutrade-client/socialevents/api/user/hashtags/{{hastagID}}`
/*** Social End ***/

export const EP_GET_TRADING_CHART_DATA = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/{{TradeID}}/performance/chart?type={{type}}&timespan={{timespan}}&timeframe={{timeframe}}`  
export const EP_GET_TRADING_DRAWDOWN_CHART_DATA = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/{{TradeID}}/performance/chart?type={{type}}&timespan={{timespan}}&timeframe={{timeframe}}` 
export const EP_GET_TRADING_SLIPPAGE_CHART_DATA = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/{{TradeID}}/performance/chart?type=SLIPPAGE`  
export const EP_GET_FOLLWERS_FOLLOWING_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/followers/api/brokerAccounts/{{TradeID}}/providers/following?page=0&size=2000` 
export const EP_GET_FOLLWERS_HAS_FOLLOWED_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/followers/api/brokerAccounts/{{TradeID}}/providers/hasFollowed?page=0&size=2000` 
export const EP_GET_FOLLWERS_CHART_DATA = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/followers/{{BrokerId}}/providers/{{TradeID}}/history/graph`  
export const EP_GET_TRADER_COMPARE_PERMORMANCE_DATA = `${GLOBAL_BASE_URL}/zulutrade-client/v2/api/providers/performance/search`  
export const EP_GET_FOLLOWERS_COMPARE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/search` 
export const EP_GET_ALL_TRADERS_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/v2/api/providers/performance/search` 
export const EP_GET_COUNTRIES_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/api/providers/performance/countries?flavor=global`  
export const EP_GET_CURRENCIES_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/traderstats/v2/api/providers/performance/currencies?flavor=global` 
export const EP_ADD_TRADER_CUSTOM_VIEW = `${GLOBAL_BASE_URL}/zulutrade-client/traders/api/zuluAccounts/performanceUserViews`   
export const EP_GET_CUSTOM_VIEW_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/traders/api/zuluAccounts/performanceFilters`
export const SOCIAL_REPORT = `${GLOBAL_BASE_URL}/zulutrade-client/translators/api/translate` 
export const EP_GET_COUNTRY_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/utils/api/countries?getOnlyEuMmber=false`   
export const EP_GET_USER_DETAIL = `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/details`
export const EP_PUT_USER_NAME = `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/profile/name`  
export const EP_UPLOAD_USER_IMAGE = `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/avatar`  
export const EP_SEND_SMS_MOBILE = `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/verification/sms/send`  
export const EP_RESEND_OTP = `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/verification/email/send`
export const EP_VERIFY_MOBILE_OTP = `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/verification/sms/validate` 
export const EP_VERIFY_OTP = `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/verification/email/validate`
export const EP_GET_WATCHLIST = `${GLOBAL_BASE_URL}/zulutrade-client/api/customer/watchlist?timeframe={{timeframe}}`
export const EP_ADD_TO_WATCHLIST = `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/watchlist`
export const EP_GET_ALL_CURRENCY_CATEGORIES = `${GLOBAL_BASE_URL}/zulutrade-client/statsservice/v2/api/markets/search`   
export const EP_GET_MARKET_CATEROGY_WISE = `${GLOBAL_BASE_URL}/zulutrade-client/stats-service/api/markets/searchGrouped`  
export const EP_GET_ASSET_DETAILS = `${GLOBAL_BASE_URL}/zulutrade-client/statsservice/api/markets/search`
/*** Subscription Start ***/
export const EP_GET_SUBSCRIPTION_PLANS = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/payments/v2/membership/availablePlans`
// export const EP_GET_SELECTED_PLAN = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/customer/planDetails`
export const EP_GET_SELECT_PLAN = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/customer/membershipPlan`
export const EP_GET_ACCOUNTS_CONNECTED = `${GLOBAL_BASE_URL}/zulutrade-client/flavors/v2/api/brokers/listForRegistration?all=true`
// export const EP_GET_ALL_BROKERS_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/brokers/api/brokers/available`
export const EP_GET_CANCEL_PLAN = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/customer/membershipPlan`
export const EP_GET_SAVED_CARDS = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/checkout/customers/instruments`
export const EP_SAVE_CARDS = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/card/add`
export const EP_INITIATE_PAYMENT = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/checkout/payments`
export const EP_GET_TRANSACTION_DETAILS = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/customer/subscription`
export const EP_GET_SAVED_CUST_CHECKOUT = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/checkout/customers`
export const EP_ADD_PAYMENT_METHOD = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/checkout/customers/instruments`
export const EP_DELETE_PAYMENT_METHOD = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/checkout/instruments/{{ID}}`
export const EP_DEFAULT_PAYMENT_METHOD = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/checkout/instruments/{{ID}}/true`

/*** Subscription End ***/

/*** Rewards Start ***/
export const EP_REWARDS_DETAILS = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/customer/rewards`
export const EP_WITHDRAWAL_HISTORY = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/providers/payment`
export const EP_REWARDS_HISTORY = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/providers/payment/details/breakdown`
export const EP_WITHDRAWAL_FUNDS = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/providers/payment/info`
export const EP_REQUEST_PAYMENT = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/providers/payment`
export const EP_EXISTING_PAYMENT = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/affiliates/payment/info`
export const EP_CANCEL_REQUEST = `${GLOBAL_BASE_URL}/zulutrade-client/payments/api/providers/payment/cancel`
/*** Rewards End ***/

/*** Trading Accounts Start ***/
export const EP_BECOME_LEADER = `${GLOBAL_BASE_URL}/zulutrade-client/traders/api/providers/leader?performActions=true`
export const EP_GET_PROVIDER_ACCOUNT_DETAIL = `${GLOBAL_BASE_URL}/zulutrade-client/traders/api/providers/{{id}}/profile?bringNonPublic=false`
export const EP_GET_BROKER_ACCOUNT_DETAIL = `${GLOBAL_BASE_URL}/zulutrade-client/users/api/brokerAccounts/{{BROKER_ID}}/trading/details`
export const EP_POST_BROKER_ACCOUNT_DETAIL = `${GLOBAL_BASE_URL}/zulutrade-client/users/api/brokerAccounts/{{BROKER_ID}}/trading/details`
export const EP_UPDATE_DESC = `${GLOBAL_BASE_URL}/zulutrade-client/traders/api/providers/leader/strategy/update`
/*** Trading Accounts End ***/

/*** Dashboad portfolio Start ***/
export const EP_PROVIDER_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/followers/v3/api/user/providers/following/settings`
export const EP_PROVIDER_OPEN_TRADES = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/open?`
export const EP_PROVIDER_PENDING_TRADES = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/orders?targetBrokerAccountId={{ID}}&page={{page}}&size={{size}}`
export const EP_PROVIDER_HISTORY_TRADES = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/history?targetBrokerAccountId={{ID}}&page={{page}}&size={{size}}`
export const EP_OPEN_TRADE_DETAILS= `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/{{BROKER_TICKET}}/open/details?targetBrokerAccountId={{targetBrokerAccountId}}`
export const EP_CLOSED_TRADE_DETAILS= `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/{{BROKER_TICKET}}/history/details?targetBrokerAccountId={{targetBrokerAccountId}}`
export const EP_PENDING_ORDER_DETAILS= `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/{{BROKER_TICKET}}/orders/details?targetBrokerAccountId={{targetBrokerAccountId}}`
export const EP_CHANGE_PASSWORD= `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/password/update`
/*** Dashboad portfolio End ***/

export const EP_GET_STRATEGY_WISE_CURRENCY = `${GLOBAL_BASE_URL}/zulutrade-client/statsservice/api/markets/search`
export const EP_GET_ASSET_TRADES = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/open?timeframe=10000&calculateProfit=true&cid={{cid}}`
export const EP_GET_MARKET_NEWS = `https://betapi.portfolios.com/get/news`
export const EP_GET_ASSET_NEWS = `https://betapi.portfolios.com/get/newsby/symbol`
export const EP_GET_ASSET_PENDING_TRADES = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/orders?timeframe=10000&calculateProfit=true&cid={{cid}}`
export const EP_GET_ASSET_CLOSED_TRADES = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/history?timeframe=10000&calculateProfit=true&cid={{cid}}`
export const EP_GET_CURRENCY_WITH_ID = `${GLOBAL_BASE_URL}/zulutrade-client/utils/api/currencies/names`
export const EP_GET_CUSTOMER_DETAIL = `${GLOBAL_BASE_URL}/zulutrade-client/api/customer`
export const EP_GET_CONVESRTION_STATS = `${GLOBAL_BASE_URL}/zulutrade-client/api/trading/conversion?targetBrokerAccountId={{ID}}`
export const EP_ASSET_PLACE_ORDER = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/trades/open/market?targetBrokerAccountId={{ID}}`
export const EP_ASSET_PENDING_ORDER = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/trades/open/pending?targetBrokerAccountId={{ID}}`
export const EP_CLOSE_OPEN_ORDER = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/trades/{{ID}}/closeMarket?targetBrokerAccountId={{targetBrokerAccountId}}`
export const EP_CLOSE_PENDING_ORDER = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/trades/{{ID}}/closePending?targetBrokerAccountId={{targetBrokerAccountId}}`
export const EP_GET_TRADER_COPY_STATS = `${GLOBAL_BASE_URL}/zulutrade-client/followers/v3/api/user/providers/following/settings?targetZuluAccountId={{ID}}`

export const GET_QUESTIONARIES_STATUS= `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/questionnaire/2`

/* OLD */
export const EP_COPY_LEADER = `${GLOBAL_BASE_URL}/zulutrade-client/followers/v2/api/user/follow/provider?targetZuluAccountId={{ID}}`
export const EP_UPDATE_COPY_STATEGY = `${GLOBAL_BASE_URL}/zulutrade-client/followers/v2/api/user/follow/provider/update?targetZuluAccountId={{ID}}`
export const EP_DELETE_COPY_STATEGY = `${GLOBAL_BASE_URL}/zulutrade-client/followers/v2/api/user/unfollow/provider/{{LEADER_ID}}?targetZuluAccountId={{ID}}&closeAllTrades={{ans}}`

/* NEW */
// export const EP_COPY_LEADER = `${GLOBAL_BASE_URL}/zulutrade-client/followers/api/user/copy/provider?targetZuluAccountId={{ID}}`
// export const EP_UPDATE_COPY_STATEGY = `${GLOBAL_BASE_URL}/zulutrade-client/followers/api/user/copy/provider?targetZuluAccountId={{ID}}`
// export const EP_DELETE_COPY_STATEGY = `${GLOBAL_BASE_URL}/zulutrade-client/followers/api/user/copy/provider/{{LEADER_ID}}?targetZuluAccountId={{ID}}&closeAllTrades={{ans}}`

export const EP_GET_MARKET_CLOSE_DAYS = `${GLOBAL_BASE_URL}/zulutrade-client/utils/api/currency/tradingSession?currencyName={{ASSET}}`
export const EP_ASSET_SEARCH_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/statsservice/api/markets/search`
export const EP_GET_USER_DASHBOARD_DATA = `${GLOBAL_BASE_URL}/zulutrade-client/followers/api/followers/performance/snapshot?`
export const EP_GET_ALL_BASECURRENCY = `${GLOBAL_BASE_URL}/zulutrade-client/utils/api/baceCurrency/all/byId`
export const EP_GET_USER_CHART_WITH_LEADER = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/history/chart?`
export const EP_GET_ALL_NOTIFICATIONS = `${GLOBAL_BASE_URL}/zulutrade-client/notifications/api/account/trade/logs?`
export const EP_UPDATE_SL = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/trades/{{TICKET}}/stop?targetBrokerAccountId={{ID}}`
export const EP_UPDATE_TP = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/trades/{{TICKET}}/limit?targetBrokerAccountId={{ID}}`
// export const EP_GET_FLAVOR_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/flavors/api/flavors/detect/light`
// export const EP_GET_LOGIN_FLAVOR_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/flavors/api/flavors/light/secure`
export const EP_GET_LEVERAGE_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/utils/api/tools/leverages`

/* Calendar Start */
export const EP_CALENDAR = `https://betapi.portfolios.com/get/calendar`
export const EP_CALENDAR_TICKER_HISTORY = `https://betapi.portfolios.com/get/ticker/history`
/* Calendar End */

/* Feedback Start */
export const EP_FEEDBACK = `${GLOBAL_BASE_URL}/zulutrade-client/users/api/user/feedback`
/* Feedback End */

/* Blogs */
export const EP_GET_BLOG_CATEGORY_WISE_DATA = `https://blog.zulutrade.com/wp-json/wp/v2/posts?categories={{category}}&per_page={{limit}}&page={{page}}`
export const EP_GET_BLOG_DETAIL = `https://blog.zulutrade.com/wp-json/wp/v2/posts?slug={{slug}}`
export const EP_GET_BLOG_DETAIL_ID = `https://blog.zulutrade.com/wp-json/wp/v2/posts/{{slug}}`
/* Blogs */

export const EP_BROKER_RAISE_QUERY = `${GLOBAL_BASE_URL}/zulutrade-client/brokers/api/brokers/request/new`
export const EP_AFFILATE_LANDED = `${GLOBAL_BASE_URL}/zulutrade-client/attributions/api/attribution/landed`

// Notification Setting
export const NOTIFICATION_SETTING = `${GLOBAL_BASE_URL}/zulutrade-client/notifications/api/preferences`
export const EP_GET_BROKER_STABILITY_QUESTIONS_LIST = `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/questionnaire?status=PENDING`

// Account Setting
export const MONITOR_ACCOUNT_SETTING= `${GLOBAL_BASE_URL}/zulutrade-client/users/api/brokerAccounts/settings`
export const NEWSLETTER_SETTING= `${GLOBAL_BASE_URL}/zulutrade-client/customers/api/customer/contact?`

// Close All Position's
export const CLOSE_ALL_TRADES= `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/close`

// Cancelled Order Log's
export const CANCELLED_ORDERS_LOG= `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/cancelledOrders?`

// Trade History in Dashboard
export const TRADE_HISTORY = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/history?`
export const TRADE_HISTORY_CHART = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/history/chart?`
export const TRADE_HISTORY_LEADERS = `${GLOBAL_BASE_URL}/zulutrade-client/followers/api/user/{{brokerID}}/followed/providers`
export const TRADE_HISTORY_CURRENCY = `${GLOBAL_BASE_URL}/zulutrade-client/trading/api/user/trades/history/currency?`