<template>
    <div :class="[{'getScrollList':locate=='header'}]" v-if="getLeaderList.length">
        <div class="flex-between px-3" :class="[{'list':locate=='header'},{'mb-3':locate=='body'}]" v-for="value,item in getLeaderList" :key="item">
            <!------Leader----------->
            <div class="d-flex align-items-center vueRisk">
                <span class="me-2 d-flex align-items-center">
                    <router-link :to="`/trader/${value.id}/trading?t=10000&m=1`">
                        <span class="position-relative">
                            <v-lazy-image width="30" height="30" class="euCurrency rounded-circle" :src="!value.photoApproved ? (value.customerAvatarUrl ? value.customerAvatarUrl : static_vars.imagesURL + 'webservices/Image.ashx?type=provider&size=XL&id=' + value.id + '&ignore=false') : static_vars.imagesURL + 'webservices/Image.ashx?type=provider&size=XL&id=' + value.id + '&ignore=false'" :alt="value.name" :title="value.name" />
                            <span :title="static_vars.riskTooltip" class="count position-absolute" :class="(value.risk) ? riskJson[value.risk] : ''">{{value.risk}}</span>
                        </span>
                    </router-link>
                </span>
                <div class="flex-shrink-0">
                    <router-link :to="`/trader/${value.id}/trading?t=10000&m=1`">
                        <p class="medium f-14 mb-0">{{value.name || ''}}</p>
                    </router-link>
                </div>
            </div>
            <div class="d-flex align-items-center">
               <div :id="'dashboardLeader'+value.id+locate" style="width: 80px; height: 30px;" class="rates-graph" v-show="locate=='body' && value.chart.data.length"></div>
                <p class="ms-3 mb-0 py-0 px-1 medium d-inline-flex" :class="[{'greenView' :(parseFloat(value.roi) >= 0)},{'redView' :(parseFloat(value.roi) < 0)},{'f-13':locate=='header'}]">
                    {{parseFloat(value.roi) >= 0.0 ? '+' : ''}}{{parseFloat(value.roi).toFixed(2) || 0}}%
                </p>
            </div>
        </div>
    </div>
    <div class="my-4 text-center" v-else>
        <i><v-lazy-image width="80" src="/assets/images/watchlist/empty-star.png" :alt="$t('watchlist_widget.alt_text')" /></i>
        <h6 class="mb-0 mt-2 medium fst-normal">{{$t('watchlist_widget.watchlistEmpty')}}</h6>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                riskJson : {
                    1 : 'blueBtn',
                    2 : 'greenBtn',
                    3 : 'yellowBtn',
                    4 : 'redBtn',
                    5 : 'pinkBtn'
                }
            };
        },
        props: ["locate"],
        computed: {
            getLeaderList() {
                if (this.store.leaderWatchList.length) {
                    return this.store.leaderWatchList.filter((i) => i.type == "LEADER");
                } else {
                    return [];
                }
            },
        },
        methods: {
            drawMostPopularChart(id, data) {
                let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === id);
                d?.dispose();
                var root = am5.Root.new(id);
                root.interfaceColors.set("grid", am5.color(0xffffff));
                root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);
                root.numberFormatter.set("numberFormat", "#,###.00");
                var chart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        focusable: false,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingTop: 0,
                    })
                );

                // Add cursor
                // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
                var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
                cursor.lineY.set("visible", false);
                cursor.lineX.set("visible", false);
                // Create axes
                // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
                var xAxis = chart.xAxes.push(
                    am5xy.DateAxis.new(root, {
                        maxDeviation: 0.2,
                        visible: false,
                        baseInterval: {
                            timeUnit: "hour",
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {
                            //minGridDistance: 10,
                        }),
                    })
                );
                // var label2 = am5.Label.new(root, {
                //   text: "[#666] Date [/]",
                //   x: am5.p50,
                //   centerY: am5.p50,
                //   fontSize: 12,
                // });
                // xAxis.children.push(label2);
                var yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        visible: false,
                        strictMinMax: true,
                        extraMax: 0.03,
                        extraMin: 0.03,
                        renderer: am5xy.AxisRendererY.new(root, {}),
                    })
                );
                // var label1 = am5.Label.new(root, {
                //     rotation: -90,
                //     text: "[#666] Ranking [/]",
                //     y: am5.p50,
                //     centerX: am5.p50,
                //     fontSize: 12,
                //   });
                // yAxis.children.unshift(label1);

                chart.get("colors").set("colors", [am5.color(0xff7200), am5.color(0x4572a7)]);

                let yRenderer = yAxis.get("renderer");
                yRenderer.grid.template.setAll({
                    stroke: am5.color(0x666666),
                    strokeWidth: 1,
                });

                var series = chart.series.push(
                    am5xy.SmoothedXLineSeries.new(root, {
                        name: "Series",
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "y",
                        valueXField: "x",
                        tooltip: am5.Tooltip.new(root, {
                            labelText: "{valueY}",
                        }),
                    })
                );
                series.strokes.template.setAll({
                    strokeWidth: 2,
                });
                // Set data
                series.data.setAll(data);
                //legend.data.push(series);
                series.appear(1000);
                chart.zoomOutButton.set("forceHidden", true);

                chart.appear(1000, 100);
            },
        },
        mounted() {
            if (this.getLeaderList.length) {
                this.getLeaderList.map((i) => {
                    this.drawMostPopularChart(`dashboardLeader${i.id}${this.locate}`, i.chart.data);
                });
            }
        },
    };
</script>
