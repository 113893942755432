<template>
   <AppLoader v-if="store.loading && $route.name == 'login'" />
  <router-view />
</template>

<script>
//import { ProviderCacheMixins } from './plugins/vue-provider-cache/mixin.ts'
// import { commonAllmixins } from './plugins/commonAll'
import { myStore } from "@/store/pinia-store";
//import { Const } from '@/plugins/vue-provider-cache/const.mod'
//import crypto_list from '../public/assets/js/crypto-list.json'
import seoData from '../public/assets/js/seo.js'
import moment from 'moment'
export default {
   setup() {
        const store = myStore();
        return { store };
    },
  // mixins: [commonAllmixins],
  watch:{
    '$route'(){
      if(this.$route.path){
        let seoJson = {}
        seoJson = seoData[0]
        let key = this.$route.path.split('/');
        let path = '';
        if(key.length > 2){
          path = `${this.$route.path.split('/')[1]}/${this.$route.path.split('/')[2]}`
        }else{
          path = this.$route.path.split('/')[1]
        }
        let getSeoPath = seoJson[path]
        if(path == undefined || seoJson[path] == undefined ){
          getSeoPath = seoJson[""]
        }
        if(getSeoPath){
            document.title = getSeoPath.META_TITLE;
            document.querySelector('meta[name="description"]').setAttribute("content", getSeoPath.META_DESCRIPTION);
            //document.querySelector('meta[name="keywords"]').setAttribute("content", getSeoPath.META_KEYWORDS);
          /*this.pageTitle = getSeoPath.META_TITLE
          this.pageDescription = getSeoPath.META_DESCRIPTION
          this.pageKeywords = getSeoPath.META_KEYWORDS*/
        } 
      }
    }
   },
   methods:{
    getAllCurrencies(){
        let json = {
            'from' : moment().subtract(1,'days').format('YYYY-MM-DD'),
            'to' : moment().format('YYYY-MM-DD')
        }
        this.store.getMarketCaterogyWise(json,false)
    },
   },
   created(){
      setTimeout(() =>{
          this.store.affilateLanded({},false)
      },3000);
      if(!this.store.user.access_token){
        this.getAllCurrencies();
      }
   }
};
</script>
<style>
@import "../node_modules/vue-toastification/dist/index.css";
  .Vue-Toastification__toast-body {
    font-size: 13px !important;
    line-height: 18px !important;
  }
  .Vue-Toastification__toast {
    padding: 20px 13px;
    max-width: 460px;
  }
</style>
