<template>
    <ul :class="(locate=='header') ? 'getScrollList' : 'mktWatchlist mb-0'" v-if="getMarketList.length">
        <li class="flex-between mx-3 position-relative" :class="[{'list':locate=='header'},{'mb-3':locate=='body'}]"  v-for="value,item in getMarketList" :key="item">
            <div class="d-flex align-items-center vueRisk ps-2">
                <span class="me-2 d-flex align-items-center">
                    <router-link :to="{name:'rates',query:{insID: value.id}}">
                        <v-lazy-image width="30" height="30" class="euCurrency" :src="static_vars.marketImageSURL+value.name.toUpperCase().replace(/\//g, '')+'.svg'" :alt="value.name" :title="value.name" />
                    </router-link>
                </span>
                <div class="flex-shrink-0">
                    <router-link :to="{name:'rates',query:{insID: value.id}}">
                        <p class="medium f-14 mb-0">{{value.name || ''}}</p>
                    </router-link>
                </div>
            </div>
            <div class="d-flex align-items-center">
                <div :id="'dashboardMarket'+value.id+locate" style="width: 80px; height: 30px;" class="rates-graph" v-show="locate=='body' && ((value.chart || {}).data || []).length"></div>
                <p class="mb-0 bold mx-2 maxValueAf" :class="[{'f-14':locate=='header'}]">{{(value.name in store.allPrices) ? store.allPrices[value.name].buyPrice : value.currentPrice}}</p>
                <p class="mb-0 py-0 px-1 medium d-inline-flex" :class="[{'greenView':parseFloat(value.dailyPriceChangePercentage) >= 0.0},{'redView':parseFloat(value.dailyPriceChangePercentage) < 0.0},{'f-13':locate=='header'}]">
                    {{parseFloat(value.dailyPriceChangePercentage) >= 0.0 ? '+' : ''}}{{parseFloat(value.dailyPriceChangePercentage).toFixed(2) || 0}}%
                </p>
            </div>
            <div class="d-flex align-items-center hoverBuySell" v-if="locate =='body'">
                <button class="button buyBtn fillBtn zulu_btn  me-2" @click.stop="tradeType = 'buy';instrumentDetail.symbol = value.name;instrumentDetail.dailyPriceChangePercentage = value.dailyPriceChangePercentage"  :class="{'disabled' : store.customerDetail?.readOnly}">{{$t('buysell.text14')}}</button>
                <button class="button sellBtn fillBtn zulu_btn" @click.stop="tradeType = 'sell';instrumentDetail.symbol = value.name;instrumentDetail.dailyPriceChangePercentage = value.dailyPriceChangePercentage"  :class="{'disabled' : store.customerDetail?.readOnly}">{{$t('buysell.text15')}}</button>
            </div>
        </li>
    </ul>
    <div class="my-4 text-center" v-else>
        <i><v-lazy-image width="80" src="/assets/images/watchlist/empty-star.png" :alt="$t('watchlist_widget.watchlist')" /></i>
        <h6 class="mb-0 mt-2 medium fst-normal">{{$t('watchlist_widget.watchlistEmpty')}}</h6>
    </div>
    <BuySell  v-if="tradeType" />
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import * as am5 from "@amcharts/amcharts5";
    import * as am5xy from "@amcharts/amcharts5/xy";
    import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
    import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
    import BuySell from '../../buy-sell'
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                renderComponent : true,
                tradeType : '',
                instrumentDetail : {'symbol' : '','dailyPriceChangePercentage' : 0}
            };
        },
        props: ["locate"],
        components : {
            BuySell
        },
        // watch : {
        //     'store.watchList'(){
        //         if(this.getMarketList.length && this.$route.name == 'dashboard_portfolio'){
        //             this.getMarketList.map(i => {
        //                 if(i.type == 'MARKET'){
        //                     console.log('===>i',i.id)
        //                     this.drawMostPopularChart(`dashboardMarket${i.id}${this.locate}`, i.chart.data);
        //                 }
        //             })
        //         }
        //     }
        // },
        computed: {
            getMarketList() {
                if (this.store.watchList.length) {
                    return this.store.watchList.filter((i) => i.type == "MARKET");
                } else {
                    return [];
                }
            },
        },
        methods: {
            drawMostPopularChart(id, data) {
                let d = am5.registry.rootElements.find((i) => i.dom && i.dom.id === id);
                d?.dispose();
                var root = am5.Root.new(id);
                root.interfaceColors.set("grid", am5.color(0xffffff));
                root.setThemes([am5themes_Animated.new(root), am5themes_Responsive.new(root)]);
                root.numberFormatter.set("numberFormat", "#,###.00");
                var chart = root.container.children.push(
                    am5xy.XYChart.new(root, {
                        focusable: false,
                        paddingLeft: 0,
                        paddingRight: 0,
                        paddingBottom: 0,
                        paddingTop: 0,
                    })
                );

                // Add cursor
                // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
                var cursor = chart.set("cursor", am5xy.XYCursor.new(root, {}));
                cursor.lineY.set("visible", false);
                cursor.lineX.set("visible", false);
                // Create axes
                // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
                var xAxis = chart.xAxes.push(
                    am5xy.DateAxis.new(root, {
                        maxDeviation: 0.2,
                        visible: false,
                        baseInterval: {
                            timeUnit: "hour",
                            count: 1,
                        },
                        renderer: am5xy.AxisRendererX.new(root, {
                            minGridDistance: 10,
                        }),
                    })
                );
                // var label2 = am5.Label.new(root, {
                //   text: "[#666] Date [/]",
                //   x: am5.p50,
                //   centerY: am5.p50,
                //   fontSize: 12,
                // });
                // xAxis.children.push(label2);
                var yAxis = chart.yAxes.push(
                    am5xy.ValueAxis.new(root, {
                        visible: false,
                        strictMinMax: true,
                        extraMax: 0.03,
                        extraMin: 0.03,
                        renderer: am5xy.AxisRendererY.new(root, {}),
                    })
                );
                // var label1 = am5.Label.new(root, {
                //     rotation: -90,
                //     text: "[#666] Ranking [/]",
                //     y: am5.p50,
                //     centerX: am5.p50,
                //     fontSize: 12,
                //   });
                // yAxis.children.unshift(label1);

                chart.get("colors").set("colors", [am5.color(0xff7200), am5.color(0x4572a7)]);

                let yRenderer = yAxis.get("renderer");
                yRenderer.grid.template.setAll({
                    stroke: am5.color(0x666666),
                    strokeWidth: 1,
                });
                // var series = []
                // if(data.length == 1){
                //     series = chart.series.push(
                //         am5xy.ColumnSeries.new(root, {
                //             name: "Series",
                //             xAxis: xAxis,
                //             yAxis: yAxis,
                //             valueYField: "y",
                //             valueXField: "x",
                //             tooltip: am5.Tooltip.new(root, {
                //                 labelText: "{valueY}",
                //             }),
                //         })
                //     );
                // } else {
                //     series = chart.series.push(
                //         am5xy.SmoothedXLineSeries.new(root, {
                //             name: "Series",
                //             xAxis: xAxis,
                //             yAxis: yAxis,
                //             valueYField: "y",
                //             valueXField: "x",
                //             tooltip: am5.Tooltip.new(root, {
                //                 labelText: "{valueY}",
                //             }),
                //         })
                //     );
                //     series.strokes.template.setAll({
                //         strokeWidth: 2,
                //     });
                // }
                var series = chart.series.push(
                    am5xy.SmoothedXLineSeries.new(root, {
                        name: "Series",
                        xAxis: xAxis,
                        yAxis: yAxis,
                        valueYField: "y",
                        valueXField: "x",
                        tooltip: am5.Tooltip.new(root, {
                            labelText: "{valueY}",
                        }),
                    })
                );
                series.strokes.template.setAll({
                    strokeWidth: 2,
                });
                // Set data
                series.data.setAll(data);
                //legend.data.push(series);
                series.appear(1000);
                chart.zoomOutButton.set("forceHidden", true);

                chart.appear(1000, 100);
            },
        },
        mounted() {
            if (this.getMarketList.length) {
                this.getMarketList.map((i) => {
                    this.drawMostPopularChart(`dashboardMarket${i.id}${this.locate}`, i.chart.data);
                });
            }
        },
    };
</script>
