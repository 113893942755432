<template>
    <div class="card-body vueCard element">
        <div class="card-header bg-white pb-3 flex-between">
            <p class="f-20 medium mb-0 d-flex align-items-center">
                <v-lazy-image class="me-3" src="/assets/images/starIcons.svg" :alt="$t('marketList.text21')" width="40" height="40" v-if="locate=='body'"/> 
                {{$t('marketList.text11')}}</p>
            <router-link v-if="locate=='body'" to="/watchlist" class="roundArrow" href="javascript:void(0)"><vue-feather type="chevron-right"></vue-feather></router-link>
        </div>
        <div class="newFillTabs mb-3 px-3">
            <ul id="tabs-nav" class="zuluTabs d-flex align-items-center px-0 mb-0">
                <!-- <li :class="[{ 'active': tab == 'All' }]"><a class="f-14" href="javascript:void(0)" @click="changeTab('All')">All</a></li> -->
                <li :class="[{ 'active': tab == 'MARKET' }]"><a class="f-14" href="javascript:void(0)" @click="changeTab('MARKET')">
                    {{$t('sitemap.sitemapcontent20')}}</a></li>
                <li :class="[{ 'active': tab == 'LEADER' }]"><a class="f-14" href="javascript:void(0)" @click="changeTab('LEADER')">
                    {{$t('sitemap.sitemapcontent24')}}</a></li>
            </ul>
        </div>
        <Search :tab="tab" :defaultList="'no'" v-if="locate !='header'"/>
        <MarketWatchlist :locate="locate" v-if="tab == 'MARKET'" />
        <LeaderWatchlist :locate="locate" v-if="tab == 'LEADER'" />
        <div v-if="locate=='header'" class="text-center bodyBG p-3"><router-link class="d-inline-flex justify-content-center f-14 secondary" to="/watchlist">{{$t('marketList.text20')}}</router-link></div>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    import MarketWatchlist from "./marketWatchlist";
    import LeaderWatchlist from "./leaderWatchlist";
    import Search from "@/components/global-search/search-watchlist.vue"
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                tab: "MARKET",
            };
        },
        props: ["locate"],
        components: {
            MarketWatchlist,
            LeaderWatchlist,
            Search
        },
        methods: {
            changeTab(val) {
                this.tab = val;
            },
        },
    };
</script>
