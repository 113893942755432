<template>
	<div class="modal fade d-block show">
		<div class="modal-dialog modal-dialog-centered">
			<div class="modal-content">
				<div class="modal-header">
					<p class="mb-0 f-20 fw-300">{{$t('login_popup.text1')}}</p>
					<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" @click="$parent.showLoginPopup=false"></button>
				</div>
				<div class="modal-body">
				<div class="block1 dashed pb-3 mb-3">
					<div class="form-group d-flex">
						<p>{{$t('login_popup.text2')}}</p>
					</div>
					<div class="dualButton d-flex align-items-center justify-content-end">
						<router-link to="/login" class="button zulu_btn rounded border-button me-2">{{$t('login_popup.text3')}}</router-link>
						<a :href="static_vars.domainURL+'register/'" class="button zulu_btn fillBtn">{{$t('login_popup.text4')}}</a>
					</div>
				</div>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
import { myStore } from "@/store/pinia-store";
export default {
	setup() {
		const store = myStore();
		return { store };
	},
	data(){
		return {

		}
	}
};
</script>