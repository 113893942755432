<template>
    <!-- Modal -->
    <div class="modal fade muySellModal modalFirstLogin show ">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header position-absolute bg-white border-0 pb-0"  @click="closePopup()">
                    <button type="button" class="btn-close"></button>
                </div>
                <div class="modal-body p-0">
                        <v-lazy-image class="mb-0 w-100" src="/assets/images/compareIcons.png" height="246" />
                        <div class="py-3 px-4">
                            <p class="f-14 mb-1 secondary uppercase">{{$t('old_user.text1')}}</p>
                            <h5 class="bold f-22 medium">{{$t('old_user.text2')}}</h5>
                            <p class="f-14 mb-2">{{$t('old_user.text3')}}<b>{{$t('old_user.text4')}}</b>.</p>
                            <p class="f-14 mb-2">{{$t('old_user.text5')}}<b>{{$t('old_user.text6')}}</b> {{$t('old_user.text7')}} <b>{{$t('old_user.text8')}}</b> {{$t('old_user.text9')}}<b>{{$t('old_user.text10')}}</b>.</p>
                            <p class="f-14">{{$t('old_user.text11')}}</p>
                            <button @click="closeRedirect()" class="button fillBtn zulu_btn">{{$t('old_user.text12')}}</button>
                        </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                
            };
        },
        methods:{
            closePopup(){
                if (localStorage.getItem("migrationUser") !== null) {
                    localStorage.setItem("migrationUser", 'no');
                    this.$parent.showMirgrationModal = 'no'
                }
            },
            closeRedirect(){
                this.closePopup();
                this.$router.replace('/leaders')
            }
        },
        mounted(){
            if (localStorage.getItem("migrationUser") === null) {
                localStorage.setItem("migrationUser", 'yes');
                this.$parent.showMirgrationModal = 'yes'
            }else{
                let data = localStorage.getItem("migrationUser");
                localStorage.setItem("migrationUser", data);
                this.$parent.showMirgrationModal = data
            }
        }
    };
</script>

