<template>
    <div class="watchListSearch position-relative mb-3 mx-3 form-group">
        <input type="search" class="form-control" :placeholder="isLeader()?'Search for leaders':'Search for markets'" @input="Searching($event)" v-model="searchdata" />
        <a href="javascript:void(0)" class="searchIcon"><vue-feather size="18" type="search"></vue-feather> </a>
        <div class="dropdown_menu_animated p-0 globalSearch w-100 show">
            <!-- :class="[{'show' : searchdata && searchdata.length}]" -->
            <div v-if="isLeader()">
                <ul class="globaListSearch" v-if="store.searchTradersList.length && searchdata && searchdata.length">
                    <li class="d-flex align-items-center justify-content-between" v-for="item,index in store.searchTradersList" :key="index">
                        <a href="javascript:void(0)" @click="detailPage(item,'LEADER','searchList')">
                            <span class="d-flex align-items-center">
                                <v-lazy-image width="24" height="24" class="rounded-circle me-3" :src="(!item.photoApproved) ? (item.customerAvatarUrl) ? item.customerAvatarUrl : static_vars.imagesURL+'webservices/Image.ashx?type=provider&size=XS&id='+item.providerId+'&ignore=false' : static_vars.imagesURL+'webservices/Image.ashx?type=provider&size=XS&id='+item.providerId+'&ignore=false'" :alt="item.name" :title="item.name" />
                                <span class="me-3 f-15 menutext medium">{{item.name}}</span>
                            </span>
                        </a>
                        <button class="button fillBtn extraSmall zulu_btn hoverBtn added"  :class="{'disabled' : store.customerDetail?.readOnly}" v-if="isLeaderAdded(item,'LEADER','searchList')" @click="removeWatchlist(item,'LEADER','searchList')">{{$t('watchlist_widget.added')}}</button>
                        <button v-else class="button fillBtn extraSmall zulu_btn"  :class="{'disabled' : store.customerDetail?.readOnly}" @click="addWatchList(item,'LEADER','searchList')">{{$t('watchlist_widget.add')}}</button>
                    </li>
                </ul>
                <ul class="globaListSearch" v-else-if="!(searchdata && searchdata.length) && defaultList=='yes'">
                    <li class="d-flex align-items-center justify-content-between" v-for="item,index in store.topTrdaersList[0].result.slice(0,10)" :key="index">
                        <a href="javascript:void(0)" @click="detailPage(item,'LEADER','defaultList')">
                            <span class="d-flex align-items-center">
                                <v-lazy-image width="24" height="24" class="rounded-circle me-3" :src="(!item.trader.profile.photoApproved) ? (item.trader.profile.customerAvatarUrl) ? item.trader.profile.customerAvatarUrl : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false' : static_vars.imagesURL + 'webservices/Image.ashx?type=user&size=XL&id=' + item.trader.profile.zuluAccountId + '&ignore=false'" :alt="item.trader.profile.name" :title="item.trader.profile.name" />
                                <span class="me-3 f-15 menutext medium">{{item.trader.profile.name}}</span>
                            </span>
                        </a>
                        <button class="button fillBtn extraSmall zulu_btn hoverBtn added"  :class="{'disabled' : store.customerDetail?.readOnly}" v-if="isLeaderAdded(item,'LEADER','defaultList')" @click="removeWatchlist(item,'LEADER','defaultList')">{{$t('watchlist_widget.added')}}</button>
                        <button v-else class="button fillBtn extraSmall zulu_btn"  :class="{'disabled' : store.customerDetail?.readOnly}" @click="addWatchList(item,'LEADER','defaultList')">{{$t('watchlist_widget.add')}}</button>
                    </li>
                </ul>
                <p class="gray text-center" v-if="searchdata && searchdata.length && !store.searchTradersList.length">{{$t('watchlist_widget.noDataFound')}}</p>
            </div>

            <div v-if="isMarket()">
                <ul class="globaListSearch" v-if="marketAssetList.length && searchdata && searchdata.length">
                    <li class="d-flex align-items-center justify-content-between" v-for="item,index in marketAssetList" :key="index">
                        <router-link :to="{ name: 'rates', query: { insID: item.id }}">
                            <span class="d-flex align-items-center">
                                <span class="me-3">
                                    <v-lazy-image width="24" height="24" class="euCurrency" :src="static_vars.marketImageSURL+item.symbol.toUpperCase().replace(/\//g, '')+'.svg'" :alt="item.symbol.toString()" :title="item.symbol.toString()" />
                                </span>
                                <span class="me-2 f-15 menutext minWidthName medium">{{item.symbol}}</span>
                            </span>
                        </router-link>
                        <button class="button fillBtn extraSmall zulu_btn hoverBtn added"  :class="{'disabled' : store.customerDetail?.readOnly}" v-if="isLeaderAdded(item,'MARKET','searchList')" @click="removeWatchlist(item,'MARKET','searchList')">{{$t('watchlist_widget.added')}}</button>
                        <button v-else class="button fillBtn zulu_btn extraSmall"  :class="{'disabled' : store.customerDetail?.readOnly}" @click="addWatchList(item,'MARKET','searchList')">{{$t('watchlist_widget.add')}}</button>
                    </li>
                </ul>
                <ul class="globaListSearch" v-else-if="!(searchdata && searchdata.length) && defaultList=='yes'">
                    <li class="d-flex align-items-center justify-content-between" v-for="item,index in (store.categoryWiseMarket?.mostPopular || []).slice(0,10)" :key="index">
                        <router-link :to="{ name: 'rates', query: { insID: item.id }}">
                            <span class="d-flex align-items-center">
                                <span class="me-3">
                                    <v-lazy-image width="24" height="24" class="euCurrency" :src="static_vars.marketImageSURL+item.symbol.toUpperCase().replace(/\//g, '')+'.svg'" :alt="item.symbol.toString()" :title="item.symbol.toString()" />
                                </span>
                                <span class="me-2 f-15 menutext minWidthName medium">{{item.symbol}}</span>
                            </span>
                        </router-link>
                        <button class="button fillBtn extraSmall zulu_btn hoverBtn added"  :class="{'disabled' : store.customerDetail?.readOnly}" v-if="isLeaderAdded(item,'MARKET','defaultList')" @click="removeWatchlist(item,'MARKET','defaultList')">{{$t('watchlist_widget.added')}}</button>
                        <button v-else class="button fillBtn zulu_btn extraSmall"  :class="{'disabled' : store.customerDetail?.readOnly}" @click="addWatchList(item,'MARKET','defaultList')">{{$t('watchlist_widget.add')}}</button>
                    </li>
                </ul>
                <div class="nodateFound py-4" v-if="searchdata && searchdata.length && !marketAssetList.length">
                    <p class="gray text-center mb-0">{{$t('watchlist_widget.noDataFound')}}</p>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                searchdata: "",
            };
        },
        watch:{
            "tab"(){
                this.searchdata = ""
            },
        },
        // computed : {
        //     leaderLists(){
        //         return this.store.searchTradersList.filter(item => !this.store.watchList.map(v => v.id).includes(item.providerId))
        //     },
        //     marketsLists(){
        //         return this.store.assetSearchList.filter(item => !this.store.watchList.map(v => v.id).includes(item.id))
        //     }
        // },
        props: ['tab','defaultList'],
        computed:{
            marketAssetList(){
                if(this.store.categoryWiseMarket?.marketsByCategory &&  Object.keys(this.store.categoryWiseMarket?.marketsByCategory).length){
                    let data = [];
                    for(var key in this.store.categoryWiseMarket?.marketsByCategory){
                        data = data.concat(this.store.categoryWiseMarket?.marketsByCategory[key])
                    }
                    if(data.length){
                        if(this.searchdata){
                            return data.filter(i => i.name.toLowerCase().includes(this.searchdata.toLowerCase()));
                        }else{
                            return data
                        }
                    }else{
                        return []
                    }
                }else{
                    return []
                }
            }
        },
        methods: {
            detailPage(item, type, mode) {
                if (item) {
                    let path = "";
                    if (type == "LEADER") {
                        if(mode=="defaultList"){
                            path = `/trader/${item.trader.profile.id}/trading?t=10000&m=1`;
                            this.$router.push(path);
                        } else {
                            path = `/trader/${item.providerId}/trading?t=10000&m=1`;
                            this.$router.push(path);
                        }
                    } 
                    // else if (type == "MARKET") {
                    //     path = `/market-details/${item.id}/${item.symbol.includes("/") ? item.symbol.split("/").join("-") : item.symbol}`;
                    //     this.$router.push(path);
                    // }
                    this.searchdata = "";
                }
            },
            Searching(e) {
                if (e.target.value) {
                    this.searchdata = e.target.value;
                    if(this.searchdata.length){
                        if (this.isLeader() ) {
                            this.store.callFollowersCompareList({}, false, this.page, this.searchdata, "global");
                        }
                        // if (this.isMarket()) {
                        //     this.store.callassetSearchList({ searchText: this.searchdata }, false);
                        // }
                    }
                } else {
                    this.searchdata = "";
                }
            },
            isMarket() {
                if (this.tab == "MARKET") {
                    return true;
                } else {
                    return false;
                }
            },
            isLeader() {
                if (this.tab == "LEADER") {
                    return true;
                } else {
                    return false;
                }
            },
            addWatchList(item,type,mode){
                if(type=='LEADER'){
                    let payload = {
                        item: (mode=='defaultList') ? (item.trader.profile.id) : (item.providerId),
                        type: 'LEADER'
                    }
                    let json = {};
                    json['id'] = (mode=='defaultList') ? (item.trader.profile.id) : (item.providerId)
                    json['type'] = 'LEADER'
                    json['name'] = (mode=='defaultList') ? (item.trader.profile.name) : (item.name)
                    json['roi'] = 0
                    json['chart'] = {}
                    let data = this.store.watchList
                    data.push(json)
                    this.store.$patch({'watchList' : data})
                    this.store.addToWatchList(payload,false,this,'ADD','LEADER')
                } else if(type=='MARKET') {
                    let payload = {
                        item: item.id,
                        type: "MARKET",
                    };
                    let json = {};
                    json["id"] = item.id;
                    json["type"] = "MARKET";
                    json["currentPrice"] = item.currentPrice;
                    json["dailyPriceChangePercentage"] = item.lastChangePercentage;
                    json["description"] = item.description;
                    json["name"] = item.name;
                    json["chart"] = {};
                    let data = this.store.watchList;
                    data.push(json);
                    this.store.$patch({ watchList: data });
                    this.store.addToWatchList(payload,false,this,'ADD','MARKET')
                }
                // this.searchdata = '';
            },
            callWatchList(type){
                if(type=='LEADER'){
                    this.store.getWatchList({},false,"180")
                } else if(type=='MARKET') {
                    this.store.getWatchList({}, false, "1");
                }
            },
            isLeaderAdded(arr,type,mode){
                if(type=='LEADER'){
                    if(this.store.searchTradersList){
                        var result = this.store.leaderWatchList.map(i => i.id);
                        if(result.length){
                            if(result.includes(mode=="defaultList" ? arr.trader.profile.id : arr.providerId)){
                                return true
                            } else {
                                return false
                            }
                        }else{
                            return false
                        }
                    }
                } else if(type=='MARKET'){
                    if(this.store.assetSearchList){
                        result = this.store.watchList.map(i => i.id);
                        if(result.length){
                            if(result.includes(arr.id)){
                                return true
                            } else {
                                return false
                            }
                        }else{
                            return false
                        }
                    }
                }
            },
            removeWatchlist(arr,type,mode){
                if(this.isLeaderAdded){
                    if(type=='LEADER'){
                        let data = this.store.leaderWatchList.filter(i=> parseInt(i.id) != (mode=="defaultList" ? arr.trader.profile.id : arr.providerId) );
                        this.store.$patch({ leaderWatchList : data });
                        this.isLeaderAdded(arr,type,mode)
                    } else if(type=='MARKET'){
                        let data = this.store.watchList.filter(i=> parseInt(i.id) != arr.id)
                        this.store.$patch({ watchList : data})
                        this.isLeaderAdded(arr,type,mode)
                    }
                    let payload = {
                        item: type=='MARKET' ? arr.id : (mode=="defaultList" ? arr.trader.profile.id : arr.providerId),
                        type: type,
                    };
                    this.store.addToWatchList(payload, false, this, 'DELETE');
                }
            },
            ismarketAdded(){
                if(this.store.assetSearchList){
                    var added = this.store.assetSearchList.filter(item => this.store.watchList.map(v => v.id).includes(item.id))
                    return added
                }
            }
        },
        created(){
            // if(!(Object.keys(this.store.mostPolularCurrencyListRates).length)){
            //     let formData = {
            //         page: 1,
            //         limit: 10,
            //         sortingStrategy: "MOST_POPULAR"
            //     };
            //     this.store.getRatePageCategories(formData, false);   
            // }
            if(!(this.store.topTrdaersList[0] && this.store.topTrdaersList[0].result && this.store.topTrdaersList[0].result.length > 0)){
                this.store.callTopTradersList({}, false)
            }
        }
    };
</script>
