import moment from "moment"
//import staticVars from "./staticVars"
import { myStore } from '@/store/pinia-store'
import $ from 'jquery';

export const convertStringToInteger = (val) => {
    return parseFloat(val)
}

export const convertToTwoDecimalDigits = (val, digits = 2) => {
    return parseFloat(parseFloat(val).toFixed(digits))
}

export const formatCalendarDate = (date, format = 'Do MMM YYYY', offset) => {
    if (!date) return 'N/A'
    let d = moment(`${date.split('T')[0]} ${date.split('T')[1]}`, 'YYYY-MM-DD HH:mm:ss')
    let tz = offset != 0 ? offset : -(new Date().getTimezoneOffset())
     d.add(tz, 'minutes')
    if (format && format == 'duration') {
        let diff = d.diff(moment(), 'seconds')
        let duration = moment.duration(diff, 'seconds')
        return `${duration.humanize()}`
        // return `${duration.hours()}:${duration.minutes()}:${duration.seconds()}`
    }
    if (format && format == 'now') return d.fromNow()
    return d.format(format)
}

export const formatDate = (date, time) => {
    if (time) {
        return moment(date).format('Do MMM, YYYY hh:mm a')
    } else {
        return moment(date).format('Do MMM, YYYY')
    }
}

export const liveAccounts = () => {
   const store = myStore();
    if(store.userTradingAccountsList.length){
       let data = [];
        data =  store.userTradingAccountsList.filter(i => !i.demo);
        if(data.length){
            return true
        }else{
            return false
        }
    }else{
        return false
    }
}

export const getPNL = (item) => {
    try{
        const store = myStore();
        if(Object.keys(item).length && Object.keys(store.allPrices).length){
            if(item.currencyName in store.allPrices && Object.keys(store.allPrices[item.currencyName]).length){

                let currencyPrice = currencyConversion(item.currencyName,item)
                let BSprice = 0
                if(item.tradeType == 'SELL'){
                    BSprice = parseFloat(item.entryRate) - parseFloat(store.allPrices[item.currencyName].sellPrice)
                }else{
                    BSprice = parseFloat(store.allPrices[item.currencyName].buyPrice) - parseFloat(item.entryRate)
                }
                let otherPrice = 0;
                if('swaps' in item){
                    otherPrice += item.swaps
                }
                if ('commission' in item) {
                  otherPrice += item.commission;
                }
                if ('interest' in item) {
                  otherPrice += item.interest;
                }
                return parseFloat((BSprice *currencyPrice*item.amount)+otherPrice).toFixed(2)
            }else{
                let currencyPrice = currencyConversion(item.currencyName,item)
                let BSprice = 0
                if(item.tradeType == 'SELL'){
                    BSprice = parseFloat(item.entryRate) - parseFloat(item.currentRate)
                }else{
                    BSprice = parseFloat(item.currentRate) - parseFloat(item.entryRate)
                }
                let otherPrice = 0;
                if('swaps' in item){
                    otherPrice += item.swaps
                }
                if ('commission' in item) {
                  otherPrice += item.commission;
                }
                if ('interest' in item) {
                  otherPrice += item.interest;
                }
                return parseFloat((BSprice *currencyPrice*item.amount)+otherPrice).toFixed(2)
            }
        }else{
            return parseFloat(item.floatingPnl).toFixed(2)
        }
    }catch(e){
        return parseFloat(item.floatingPnl).toFixed(2)
    }
}
export const currencyConversion = (selectedSymbol,item) => {
    const store = myStore();
    // console.log('data',getQuoteCurrency(item.currencyName))
    let symbol = selectedSymbol.includes('/') ? selectedSymbol.split('/')[1] : (getQuoteCurrency(selectedSymbol) && getQuoteCurrency(selectedSymbol).quoteCurrency ?  getQuoteCurrency(selectedSymbol).quoteCurrency : 'USD');
    let priceKey = item.tradeType == 'BUY' ? 'buyPrice' : 'sellPrice'
    if(symbol == getBaseCurrency()){
        return 1
    }else if(store.allPrices[`${symbol}/${getBaseCurrency()}`]){
        return store.allPrices[`${symbol}/${getBaseCurrency()}`][priceKey]
    }else if(store.allPrices[`${getBaseCurrency()}/${symbol}`]){
        return 1/store.allPrices[`${getBaseCurrency()}/${symbol}`][priceKey]
    }else{
        let secontoUSD = 0
        let accBasetoUSD = 0
        if(store.allPrices[`${symbol}/USD`]){
            secontoUSD = store.allPrices[`${symbol}/USD`][priceKey]
        } 
        else if(store.allPrices[`USD/${symbol}`]){
            secontoUSD = 1/store.allPrices[`USD/${symbol}`][priceKey]
        } 

        if(store.allPrices[`${getBaseCurrency()}/USD`]){
            accBasetoUSD = 1/store.allPrices[`${getBaseCurrency()}/USD`][priceKey]
        } 
        else if(store.allPrices[`USD/${getBaseCurrency()}`]){
            accBasetoUSD = store.allPrices[`USD/${getBaseCurrency()}`][priceKey]
        } 

        return accBasetoUSD * secontoUSD
    }
}
export const getQuoteCurrency = (currency) => {
    try{
        const store = myStore();
        if(Object.keys(store.currencyPairs).length && currency){
            return store.currencyPairs.find(i => {
                if(i.name == currency){
                    return i
                }
            });
        }else{
            return 'EUR'
        }
    }catch(e){
        return 'EUR';
    }
}
export const realTimeGrossPnl = () => {
    try{
        const store = myStore()
        if(Object.keys(store.providerOpenTrades).length && store.providerOpenTrades.content && store.providerOpenTrades.content.length){
            let pnl = 0;
            store.providerOpenTrades.content.map(i => {
                pnl += parseFloat(getPNL(i))
            });
            return pnl
        }else{
            return 0
        }
    }catch(e){
        return 0;
    }
}
export const realTimeGrossROI = () => {
    try{
        const store = myStore();
        let pnl = 0,investment=0;
        if(Object.keys(store.userDashboardDetail).length){
            pnl = parseFloat(store.userDashboardDetail.totalPnl - store.userDashboardDetail.floatingPnl)+realTimeGrossPnl()
            investment = parseFloat(store.userDashboardDetail.equity - store.userDashboardDetail.floatingPnl)-parseFloat(store.userDashboardDetail.totalPnl - store.userDashboardDetail.floatingPnl)
            if(pnl && investment){
                return ((pnl/investment)*100)
            }else{
                return 0
            }
        }else{
            return 0
        }
    }catch(e){
        return 0;
    }
}
export const realTimeLeadersPnl = (item) => {
    try{
        const store = myStore();
        if(Object.keys(store.providerOpenTrades).length && store.providerOpenTrades.content && store.providerOpenTrades.content.length && Object.keys(item).length){
            let leaderTrades = store.providerOpenTrades.content.filter(i => i.providerId == item.providerId);
            let pnl = 0;
            if(leaderTrades.length){
                leaderTrades.map(i => {
                    pnl += parseFloat(getPNL(i))
                });
                return parseFloat((pnl+item.realizedPnl)|| 0).toFixed(2)
            }else{
                return item.realizedPnl || 0
            }
        }else{
            return item.realizedPnl || 0
        }
    }catch(e){
         return item.realizedPnl || 0;
    }
}
export const realTimeGrossROILeaders = (item) => {
    try{
        if(realTimeLeadersPnl(item) && Object.keys(item).length){
            return parseFloat(((realTimeLeadersPnl(item))/item.capitalProtectionAmount)*100).toFixed(2)
        }else{
            return item.roi || 0
        }
    }catch(e){
         return item.roi || 0 ;
    }
}
export const getBaseCurrency = () => {
    const store = myStore();
    if(store.user.access_token){
        if(store.allTypeAccount){//All demo/live
            return 'USD'
        }else{
            if(Object.keys(store.allCurrencySymbols).length && Object.keys(store.userSelectedAccount).length){
                return store.allCurrencySymbols[store.userSelectedAccount.baseCurrencyId].name
            }
        }
    }else{
        if(Object.keys(store.traderDetail).length && store.traderDetail.trader && Object.keys(store.traderDetail.trader).length && store.traderDetail.trader.stats && Object.keys(store.traderDetail.trader.stats).length && store.traderDetail.trader.stats.profile && store.traderDetail.trader.stats.profile.baseCurrencyName){
            return store.traderDetail.trader.stats.profile.baseCurrencyName
        }
    }
}
export const getBaseCurrencySymbol = () => {
    const store = myStore()
    if(Object.keys(store.allCurrencySymbols).length && Object.keys(store.userSelectedAccount).length){
        return store.allCurrencySymbols[store.userSelectedAccount.baseCurrencyId].htmlSymbol
    }else{
        if(store.allTypeAccount){//All demo/live
            return '$'
        }
    }
}
export const cretioEvent = (ID,type) => {
    if(type && ID){
        if(type == 'setAccount'){
            try{
                var s = document.createElement("script");
                s.type = "text/javascript";
                s.async = true;
                s.src = `//dynamic.criteo.com/js/ld/ld.js?a=${ID}`;
                $("head").append(s);
                window.criteo_q = window.criteo_q || [];
                var deviceType = /iPad/.test(navigator.userAgent) ? "t" : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? "m" : "d";
                window.criteo_q.push(
                    { event: "setAccount", account: ID},
                    { event: "setSiteType", type: deviceType},
                    { event: "viewHome" }
                );
            }catch(ex){
                console.log('cretio exc',ex)
            }
        }

    }
}
export const htmlToText = (text) => {
    if(text){
        var tempDivElement = document.createElement("div");
        tempDivElement.innerHTML = text;
        return tempDivElement.textContent || tempDivElement.innerText || "";
    }else{
        return ""
    }
}

