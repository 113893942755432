<template>
    <HeaderWithoutLogin v-if="!store.user.access_token" />
    <Header v-else />
    <div id="main-wrapper" class="mainWrapper pb-0">
        <AppLoader v-if="store.loading" />
        <router-view />
        <Gift v-if="store.isGiftPopup" @close="closeGift()"/>
        <OldUserPopup v-if="store.user.access_token && store.customerDetail.registrationOrigin && store.customerDetail.registrationOrigin == 'MIGRATION' && showMirgrationModal == 'yes'" />
    </div>
    <Footer />
</template>

<script>
    import Header from "@/components/shared/header";
    import HeaderWithoutLogin from "@/components/shared/header-without-login";
    import Footer from "@/components/shared/footer";
    // import AppLoader from "@/components/shared/AppLoader";
    import { myStore } from "@/store/pinia-store";
    //import moment from "moment"
    //import static_vars from '@/staticVars'
    import PriceFeed from "@/store/stomp";
    import LoginFeed from "@/store/login-stomp";
    // import Message from '@/store/message-stomp'
    import OldUserPopup from "@/components/shared/oldUser-popup";
    import * as COMMON from '../commonFunctions'
    import Gift from "@/components/shared/gift.vue"
    import moment from "moment"
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                showMirgrationModal : 'no'
            };
        },
        watch : {
            'store.user'(){
                this.store.getFlavorID({}, false);
                if(!this.store.user?.access_token){
                    this.getAllCurrencies()
                }
            },
        },
        components: { Header, Footer, HeaderWithoutLogin ,OldUserPopup, Gift},
        methods: {
            getAllCurrencies(){
                let json = {
                    'from' : moment().subtract(1,'days').format('YYYY-MM-DD'),
                    'to' : moment().format('YYYY-MM-DD')
                }
                this.store.getMarketCaterogyWise(json,false)
            },
            closeGift(){
                this.store.$patch({'isGiftPopup' : false})
            },
            getWatchList() {
                this.store.getWatchList({}, true, "1");
            },
            UserDetail() {
                this.store.getUserDetail({}, true, this, "");
            },
            getDashboardData() {
                this.store.getUserDashboardData({}, true, "", "10000");
            },
            getCopyLeaders(ID) {
                this.store.getCopyStats({}, false, ID);
            },
            getLeaderWatchList() {
                this.store.getWatchList({}, true, "180");
            },
            getTime(){
                var date = new Date();
                return date.getTime()
            },
            AllFunc(){
                 LoginFeed.getInstance().activate();
                // this.store.getFlavorID({}, false).then(response =>{
                //     if(Object.keys(response).length && response.userCountry && Object.keys(response.userCountry).length && response.userCountry.isoCode){
                //         for(var key in this.static_vars.cretioCountryJson){
                //             if(key == response.userCountry.isoCode){
                //                 COMMON.cretioEvent(this.static_vars.cretioCountryJson[key],'setAccount')
                //             }
                //         }
                //     }
                // });
                this.UserDetail();
                this.store.callCustomerDetail({}, false).then((response) => {
                    if (Object.keys(response).length && response.accounts && Object.keys(response.accounts).length) {
                        if(this.store.user?.access_token && this.store.flavordata?.isEu && this.store.liveAccounts()){
                            this.store.getQuestioanriesStatus({},false)
                        }
                        this.store.getProviderOpenTrades({}, true);
                        // this.store.accountSettings({}, true, 'get')
                        this.getDashboardData();
                        let ID = "";
                        // if(this.store.userTradingAccountsList.length){
                        //   this.store.callCurrencyPairsList({},false,this.store.userTradingAccountsList[0].feedGroupId)
                        // }
                        if (this.store.allTypeAccount) {
                            if (this.store.allTypeAccount == "ALL_LIVE") {
                                let data = this.store.userTradingAccountsList.filter((i) => i.demo === false);
                                ID = data[0].brokerAccountId;
                                this.store.callCurrencyPairsList({}, false,  data[0].feedGroupId);
                            } else if (this.store.allTypeAccount == "ALL_DEMO") {
                                let data1 = this.store.userTradingAccountsList.filter((i) => i.demo === true);
                                ID = data1[0].brokerAccountId;
                                this.store.callCurrencyPairsList({}, false,  data1[0].feedGroupId);
                            }
                        } else {
                            if (Object.keys(this.store.userSelectedAccount).length) {
                                ID = this.store.userSelectedAccount.brokerAccountId;
                            }
                            this.store.callCurrencyPairsList({}, false, (this.store.userSelectedAccount.feedGroupId || '1000'));
                        }
                        if (ID) {
                            let form = {
                                targetBrokerAccountId: ID,
                                page: 0,
                                size: 10,
                                sort: "id,desc",
                            };
                            this.store.userNotifications(form, false);
                        }
                        //this.getCopyLeaders(this.store.userSelectedAccount.zuluAccountId)
                        this.store.GetCopyTraders({}, false);
                        this.getAllCurrencies()
                        // this.store.subscriptionSelectedPlan({}, false);
                        //this.store.subscriptionCardList({},false)
                    }
                });
                this.getWatchList();
                this.getLeaderWatchList();
                this.store.$patch({ dashboardTradesTab: "open" });
            }
        },
        mounted() {
            if (this.store.user.access_token && localStorage.getItem("migrationUser") === null) {
                localStorage.setItem("migrationUser", 'yes');
                this.showMirgrationModal = 'yes'
            }else{
                if(this.store.user.access_token){
                    let data = localStorage.getItem("migrationUser");
                    localStorage.setItem("migrationUser", data);
                    this.showMirgrationModal = data
                }
            }
            if (this.store.user.access_token) {
                // LoginFeed.getInstance().activate();
                // Message.getInstance().activate();
            } else {
                PriceFeed.getInstance().activate();
            }
        },
        created() {
            if (localStorage.getItem("theme") === null) {
                localStorage.setItem("theme", "light");
            }
            this.store.getAllCurrencySymbols({}, false);
            this.store.getBaseCurrencies({}, false);
            // if(this.store.user.access_token){
                
            // }
            this.store.getFlavorID({}, false).then(response =>{
                if(Object.keys(response).length && response.userCountry && Object.keys(response.userCountry).length && response.userCountry.isoCode){
                    for(var key in this.static_vars.cretioCountryJson){
                        if(key == response.userCountry.isoCode){
                            COMMON.cretioEvent(this.static_vars.cretioCountryJson[key],'setAccount')
                        }
                    }
                }
            })

            //save token in cookies
            // if(static_vars.getCookie('zulutoken')){
            //   let json = {};
            //   json['access_token'] = this.static_vars.getCookie('zulutoken')
            //   if(static_vars.getCookie('zulurefreshToken')){
            //     json['refresh_token'] = this.static_vars.getCookie('zulurefreshToken')
            //   }
            //   this.store.$patch({'user' : json})
            // }else{
            //   if(this.store.user.access_token){
            //     static_vars.setCookie('zulutoken',this.store.user.access_token,'100d')
            //     if(this.store.user.refresh_token){
            //        static_vars.setCookie('zulurefreshToken',this.store.user.refresh_token,'100d')
            //     }
            //   }
            // }

            if (localStorage.getItem("zulutoken") !== null) {
                let json = {};
                json["access_token"] = localStorage.getItem("zulutoken");
                if (localStorage.getItem("zulurefreshToken") !== null) {
                    json["refresh_token"] = localStorage.getItem("zulurefreshToken");
                }
                this.store.$patch({ user: json });
            } else {
                if (this.store.user.access_token) {
                    localStorage.setItem("zulutoken", this.store.user.access_token);
                    if (this.store.user.refresh_token) {
                        localStorage.setItem("zulurefreshToken", this.store.user.refresh_token);
                    }
                }
            }
            //===============================//
            if (this.store.user.access_token) {
                this.store.$patch({'userProfileImage' : `${this.getTime()}`})
                let form = {
                    grant_type: "refresh_token",
                    refresh_token: this.store.user.refresh_token,
                };
                if(this.store.user.refresh_token){
                    this.store.refreshToken(form, false, "basic", this,'').then((response) => {
                        if (response.access_token) {
                            this.AllFunc();
                        }
                    });
                }else{
                    this.AllFunc();
                }
            }
            this.store.getCountryList({}, true);

            // let reffrerArr = [];
            // let reffrer = document.referrer.split('/')[2]
            // this.$cookie.set()
            
            // if(Object.keys(response).length){
            //   if(window.localStorage.storekey){
            //       const store = JSON.parse(window.localStorage.storekey)
            //       if(store.countryList){
            //         store.countryList = this.store.countriesList
            //         window.localStorage.setItem("storekey",  JSON.stringify(store))
            //          if(this.static_vars.getCookie('zuluCountryList')){
            //             this.static_vars.delete_cookie('zuluCountryList')
            //           }
            //       }
            //   }else{
            //       this.static_vars.setCookie('zuluCountryList',JSON.stringify(response),'100d')
            //   }
            // }
            //})
        },
    };
</script>
