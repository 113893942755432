<template>
    <div>
        <div class="headerLegacy text-center">
             <p class="neutralGrey f-15">{{$t('head.text1')}} <router-link :to="{name : 'introducingZuluTrade'}">{{$t('login.here')}} </router-link>. {{$t('login.existing')}} <a :href="static_vars.OldWebsiteURL" target="_blank"> {{$t('login.here')}}</a> </p>
        </div>
        <header class="mainHeader loggedHeader" id="myHeader">
            <nav class="navbar navbar-expand-lg border-bottom">
                <div class="container-fluid">
                    <span v-if="!Object.keys(store.flavordata).length || !store.flavordata.whitelabelLogo">
                        <router-link class="lightTheme navbar-brand d-flex p-0 me-3" to="/"><img width="150" src="/assets/images/logo.svg" :alt="$t('head.text21')" :title="$t('head.text22')" /></router-link>
                        <router-link class="darkTheme navbar-brand d-none p-0 me-3" to="/"><img width="150" src="/assets/images/whiteLogo.svg" :alt="$t('head.text21')" :title="$t('head.text22')" /></router-link>
                    </span>
                    <span v-else>
                        <a href="javascript:void(0);" class="lightTheme navbar-brand d-flex p-0 me-3" @click="gotoRoute()" v-if="Object.keys(store.flavordata).length && store.flavordata.whitelabelLogo"><img width="150"
                                :src="`https://www.zulutrade.com/${store.flavordata.whitelabelLogo}`" :alt="$t('head.text21')" :title="$t('head.text22')" /></a>
                        <a href="javascript:void(0);" class="darkTheme navbar-brand d-none p-0 me-3" @click="gotoRoute()" v-if="Object.keys(store.flavordata).length && store.flavordata.whitelabelLogo"><img width="150"
                               :src="`https://www.zulutrade.com/${store.flavordata.whitelabelLogo}`" :alt="$t('head.text21')" :title="$t('head.text22')" /></a>
                    </span>
                    <div class="collapse navbar-collapse" id="mynavbar">
                        <ul class="megaMenuList navbar-nav me-auto align-items-center">
                            <li class="nav-item" @click="addClass('remove')">
                                <router-link class="nav-link menuTitle dashboard" to="/dashboard">{{$t('head.text2')}}</router-link>
                            </li>
                            <li class="nav-item" @click="addClass('remove')">
                                <router-link class="nav-link menuTitle leaders" to="/leaders">{{$t('header.leader')}}</router-link>
                            </li>
                            <li class="nav-item" @click="addClass('remove')">
                                <router-link class="nav-link menuTitle markets" to="/markets">{{$t('header.market')}}</router-link>
                            </li>
                            <li class="nav-item hideTabMobile">
                                <a class="nav-link dropdown-toggle menuTitle tools" role="button" data-bs-toggle="dropdown"
                                    href="javascript:void(0)">{{$t('header.tool')}}</a>
                                <div class="dropdown-menu w-100 showMegaMenu darkCard" @click="addClass('remove')">
                                    <div class="container max-970">
                                        <div class="row justify-content-center">
                                            <div class="col-12 col-lg-4">
                                                <div class="d-flex align-items-start horizontralBorder">
                                                    <span class="icon"><v-lazy-image src="/assets/images/instruments.svg"
                                                           :alt="$t('head.text23')" :title="$t('head.text24')" /></span>
                                                    <div class="clsMail">
                                                        <h5 class="bold mb-1 secondary">{{$t('header.help')}}</h5>
                                                        <p class="mb-0 medium line-4 f-13 gray">{{$t('header.title')}}</p>
                                                        <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0">
                                                            <li><router-link class="px-0 dropdown-item" to="/help-center">{{$t('header.help')}}</router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" to="/user-guide">{{$t('header.guide')}} </router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" to="/leader-guide">{{$t('header.leader_guide')}} </router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" to="/affiliate-guide">{{$t('header.affiliate')}} </router-link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-4">
                                                <div class="d-flex align-items-start horizontralBorder">
                                                    <span class="icon"><v-lazy-image src="/assets/images/blog.svg"
                                                        :alt="$t('header.title14')" :title="$t('header.title1')" /></span>
                                                    <div class="clsMail">
                                                        <h5 class="bold mb-1 secondary">{{$t('header.blog')}} </h5>
                                                        <p class="mb-0 medium line-4 f-13 gray">{{$t('header.check_out')}}</p>
                                                        <div class="d-flex align-items-start justify-content-between blogHeader">
                                                            <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0" v-if="static_vars.BlogsArray.length">
                                                                <!-- <li><router-link  class="px-0 dropdown-item"
                                                                    :to="{name : 'marketNews'}">Market
                                                                        News</router-link></li>
                                                                <li><a class="px-0 dropdown-item"
                                                                        href="https://blog.zulutrade.com/category/Knowledge-Crunch">Knowledge
                                                                        Crunch</a></li>
                                                                <li><a class="px-0 dropdown-item"
                                                                        href="https://blog.zulutrade.com/category/New-Features">New
                                                                        Features</a></li>
                                                                <li><a class="px-0 dropdown-item"  href="https://blog.zulutrade.com/category/Trading-Floor">Trading Floor</a></li> -->
                                                            <li v-for="list in static_vars.BlogsArray" :key="list.id" @click="addClass('remove')"><router-link  class="px-0 dropdown-item"
                                                                    :to="{name : 'AllBlogs',params:{'name' : list.slug}}">{{list.name}}</router-link></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-4">
                                                <div class="d-flex align-items-start">
                                                    <span class="icon"><v-lazy-image src="/assets/images/tools.svg"
                                                        :alt="$t('header.title15')" :title="$t('header.title2')" /></span>
                                                    <div class="clsMail">
                                                        <h5 class="bold mb-1 secondary">{{$t('header.trading')}}</h5>
                                                        <p class="mb-0 medium line-4 f-13 gray">{{$t('header.use')}}</p>
                                                        <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0">
                                                            <li><router-link class="px-0 dropdown-item" to="/simulation"> {{$t('header.text1')}} </router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" to="/calendar"> {{$t('header.text2')}} </router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" :to="{name:'calculator'}"> {{$t('header.text3')}} </router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" :to="{path:'/pricing'}"> {{$t('header.text4')}} </router-link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li class="nav-item d-flex align-items-center" @click="addClass('remove')">
                                <router-link class="community nav-link menuTitle" to="/social-feed"> {{$t('header.community')}} </router-link> 
                            </li>
                            <li class="nav-item hideTabMobile">
                                <a class="nav-link dropdown-toggle menuTitle about" href="javascript:void(0)" role="button" data-bs-toggle="dropdown">{{$t('header.about')}}</a>
                                <div class="dropdown-menu w-100 showMegaMenu darkCard" @click="addClass('remove')">
                                    <div class="container max-970">
                                        <div class="row justify-content-center">
                                            <div class="col-12 col-lg-5">
                                                <div class="d-flex align-items-start horizontralBorder">
                                                    <span class="icon"><v-lazy-image src="/assets/images/zulu.svg" :alt="$t('header.title16')" :title="$t('header.title3')" /></span>
                                                    <div class="clsMail">
                                                        <h5 class="bold mb-1 secondary">{{$t('header.about_us')}} </h5>
                                                        <p class="mb-0 medium line-4 f-13 gray">{{$t('header.about_title')}}</p>
                                                        <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0">
                                                            <li><router-link class="px-0 dropdown-item" to="/about"> {{$t('header.company')}} </router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" to="/news-and-media">{{$t('header.media')}} </router-link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12 col-lg-5">
                                                <div class="d-flex align-items-start">
                                                    <span class="icon">
                                                        <v-lazy-image src="/assets/images/partnerships.svg" :alt="$t('header.title17')" :title="$t('header.partner_title')" />
                                                        </span>
                                                    <div class="clsMail">
                                                        <h5 class="bold mb-1 secondary">{{$t('header.partner')}}</h5>
                                                        <p class="mb-0 medium f-13 gray">{{$t('header.partner_title')}}</p>
                                                        <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0">
                                                            <li><router-link class="px-0 dropdown-item" to="/become-an-affiliate">{{$t('header.program1')}}</router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" to="/become-a-leader">{{$t('header.program2')}}</router-link></li>
                                                            <li><router-link class="px-0 dropdown-item" to="/partnership-program">{{$t('head.program3')}}</router-link></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <!-- Only Show for mobile -->
                            <li class="d-lg-none nav-item d-flex align-items-center clickOutRemove">
                                <a href="javascript:void(0)" class="more nav-link menuTitle" :class="showHumbugerMenu?'active':''" @click="showHumbugerMenu = !showHumbugerMenu"> {{$t('header.more')}} </a> 
                                <ul class="megaMenusubList pb-0" :class="[{'viewmoreMenu':showHumbugerMenu}]">
                                    <li class="closemenuList text-end position-fixed"><a class="secondary d-inline-flex" href="javascript:void(0)" @click="showHumbugerMenu = false"><vue-feather type="x-circle"></vue-feather></a></li>
                                    <li class="nav-item viewMobileListed">
                                        <div class="clsMail">
                                            <h5 class="bold mb-1 secondary">{{$t('header.help')}} </h5>
                                            <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0">
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/help-center">{{$t('header.help')}}</router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/user-guide">{{$t('header.guide')}}</router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/leader-guide">{{$t('header.leader_guide')}}</router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/affiliate-guide">{{$t('header.affiliate')}}</router-link></li>
                                            </ul>
                                        </div>
                                        <div class="clsMail">
                                            <h5 class="bold mb-1 secondary">{{$t('header.blog')}} </h5>
                                            <div class="d-flex align-items-start justify-content-between blogHeader">
                                                <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0" v-if="static_vars.BlogsArray.length">
                                                                <!-- <li><router-link  class="px-0 dropdown-item"
                                                                    :to="{name : 'marketNews'}">Market
                                                                        News</router-link></li>
                                                                <li><a class="px-0 dropdown-item"
                                                                        href="https://blog.zulutrade.com/category/Knowledge-Crunch">Knowledge
                                                                        Crunch</a></li>
                                                                <li><a class="px-0 dropdown-item"
                                                                        href="https://blog.zulutrade.com/category/New-Features">New
                                                                        Features</a></li>
                                                                <li><a class="px-0 dropdown-item"  href="https://blog.zulutrade.com/category/Trading-Floor">Trading Floor</a></li> -->
                                                            <li v-for="list in static_vars.BlogsArray" :key="list.id" @click="addClass('remove')"><router-link  class="px-0 dropdown-item"
                                                                    :to="{name : 'AllBlogs',params:{'name' : list.slug}}">{{list.name}}</router-link></li>
                                                            </ul>
                                                
                                            </div>
                                        </div>
                                        <div class="clsMail">
                                            <h5 class="bold mb-1 secondary">{{$t('changes.text28')}} </h5>
                                            <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0">
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/simulation"> {{$t('header.text1')}} </router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/calendar"> {{$t('header.text2')}} </router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" :to="{name:'calculator'}"> {{$t('header.text3')}} </router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" :to="{path:'/pricing'}">{{$t('header.text4')}} </router-link></li>
                                            </ul>
                                        </div>
                                        <div class="clsMail">
                                            <h5 class="bold mb-1 secondary">{{$t('header.about_us')}} </h5>
                                            <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0">
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/about"> {{$t('header.company')}} </router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/news-and-media">{{$t('header.media')}} </router-link></li>
                                            </ul>
                                        </div>
                                        <div class="clsMail">
                                            <h5 class="bold mb-1 secondary">{{$t('header.partner')}}</h5>
                                            <ul class="dropDownMenu mt-lg-3 mb-2 mb-lg-0">
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/become-an-affiliate">{{$t('header.program1')}}</router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/become-a-leader">{{$t('header.program2')}}</router-link></li>
                                                <li @click="addClass('remove')"><router-link class="px-0 dropdown-item" to="/partnership-program">{{$t('header.program3')}}</router-link></li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                            </li>
                            <!-- End  -->
                        </ul>
                    </div>
                    <div class="rightHeader d-flex align-items-center">
                        <div class="form-group headerSearch mb-0 me-md-4 position-relative" v-if="showSearch()">
                            <Search />
                        </div>
                        <ul class="belowMenu position-relative mb-0 d-flex align-items-center rightSpaceMob">
                            <li>
                                <a :title="$t('header.title4')" :class="notification?'filled menutext':''" class="d-flex px-2 notifyActive showClass" href="javascript:void(0)" @click.prevent="notification = !notification;openWatchlist=false;openMenu=false"><vue-feather size="20" type="bell"></vue-feather></a>
                                <div class="dropdown_menu_animated maxAuto dropdown-menu userLogged p-0 dropdown-menu-end notify" :class="notification?'show':''">
                                    <ul class="p-3 notificationList" v-if="Object.keys(store.usertopNotifications).length && store.usertopNotifications.content && store.usertopNotifications.content.length">
                                        <li v-for="item,index in store.usertopNotifications.content.slice(0,5)" :key="index">
                                            <p class="f-12 mb-1 line-4 neutralGrey">{{showText(item.message)}}</p>
                                            <p class="mb-0 f-11 midgray">{{fomatDate(item.dateTime)}}</p>
                                        </li>
                                    </ul>
                                    <div class="no-posts-section" v-else>
                                        <v-lazy-image src="/assets/images/noposts.png" :alt="$t('header.title18')" />
                                        <p class="mt20">{{$t('head.text3')}}</p>
                                    </div>
                                    <div class="stickyAll bodyBG text-center p-3 position-sticky">
                                        <router-link to="/notifications" class="justify-content-center secondary f-14 medium">{{$t('head.text4')}}</router-link>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul class="belowMenu mb-0 d-flex align-items-center showClass rightSpaceMob">
                            <li class="position-relative">
                                <a :title="$t('header.title5')" class="menutext filledStar" href="javascript:void(0)" @click.prevent="openWatchlist = !openWatchlist;notification=false;openMenu=false" :class="openWatchlist?'filled':''">
                                    <vue-feather size="20" type="star"></vue-feather> 
                                </a>
                                <div class="dropdown_menu_animated maxAuto dropdown-menu userWatchlist p-0 dropdown-menu-end" :class="openWatchlist?'show':''">
                                    <Market :locate="'header'"/>
                                </div>
                            </li>
                        </ul>
                        <ul class="belowMenu mb-0 d-flex align-items-center showClass rightSpaceMob">
                        <li class="nav-item">
                                <a id="theme-toggle" :title="$t('header.title6')" class="ps-1" :class="[{'light':currentTheme=='light'},{'dark':currentTheme=='dark'}]" href="javascript:void(0)" @click="changeTheme()">
                                    <vue-feather v-if="currentTheme=='light'" class="me-1" size="20" type="moon"></vue-feather> 
                                    <vue-feather v-else-if="currentTheme=='dark'" class="me-1" size="20" type="sun"></vue-feather> 
                                </a>
                            </li>
                        </ul>
                        <ul class="showClass loginRegister mb-0">
                            <li class="nav-item dropdown">
                                <a class="userLogged" href="javascript:void(0)" @click.prevent="openMenu = !openMenu;notification=false;openWatchlist=false"><!---role="button" data-bs-toggle="dropdown"-->
                                    <span class="userImg ms-0 me-0" v-if="!Object.keys(store.customerDetail).length || !Object.keys(store.userDetail).length"></span>
                                    <span class="userImg ms-0 me-0" v-else-if="Object.keys(store.customerDetail).length && store.customerDetail.imageUrl"><v-lazy-image width="35" height="35" class="cover rounded-circle m-0" :src="store.customerDetail.imageUrl+'?'+store.userProfileImage" /></span>
                                    <span v-else-if="(Object.keys(store.customerDetail).length && !store.customerDetail.imageUrl) && (!store.userDetail.firstName)" class="userIcon ms-0 me-0"></span>

                                    <span class="userIcon" v-else>{{(store.userDetail.firstName) ? store.userDetail.firstName.slice(0,1) : ''}}{{(store.userDetail.lastName) ? store.userDetail.lastName.slice(0,1) : ''}}</span>
                                </a>
                                <div class="dropdown_menu_animated maxAuto dropdown-menu userLogged p-0 dropdown-menu-end" :class="openMenu?'show':''">
                                    <ul class="p-3 mb-1 border-bottom">
                                        <li>
                                            <div class="userLogged d-flex align-items-center">
                                                <span class="userImg ms-0 me-2" v-if="Object.keys(store.customerDetail).length && store.customerDetail.imageUrl"><v-lazy-image class="w-100 h-100 cover m-0" :src="store.customerDetail.imageUrl+'?'+store.userProfileImage" /></span>
                                                <span class="userIcon ms-0 me-2" v-else>{{(store.userDetail.firstName) ? store.userDetail.firstName.slice(0,1) : ''}}{{(store.userDetail.lastName) ? store.userDetail.lastName.slice(0,1) : ''}}</span>
                                                <span class="mb-0 f-16 line-1 darkHeader medium">{{(store.userDetail.firstName) ? store.userDetail.firstName : ''}} {{(store.userDetail.lastName) ? store.userDetail.lastName : ''}}<!-- <span
                                                    class="mt-1 f-13 d-block midgray">@drsrbrtsn22</span> -->
                                                </span>
                                            </div> 
                                        </li>
                                        <li class="position-relative" :class="changeAccount?'viewShow':''">
                                            <a @click.prevent="changeAccount = !changeAccount" v-if="Object.keys(store.userSelectedAccount).length || store.allTypeAccount" href="javascript:void(0)" class="flex-between px-2 mb-1 dropSelect">
                                                <div class="d-flex align-items-start">
                                                    <span class="ms-0" v-if="!store.allTypeAccount"><v-lazy-image v-if="store.userSelectedAccount.brokerLogo" style="width:24px;" :src="static_vars.imagesURL+'/'+store.userSelectedAccount.brokerLogo" :alt="$t('header.title7')" /></span>
                                                    <div class="name mt-0">
                                                        <span class="mb-0 f-16 line-1 darkHeader" v-if="store.allTypeAccount">{{store.allTypeAccount == 'ALL_LIVE' ? 'Live Accounts' : 'Demo Accounts'}}</span>
                                                        <span class="mb-0 f-16 line-1 darkHeader" v-else>{{store.userSelectedAccount.username}}</span>
                                                    <!--  <span class="f-12 d-block midgray">{{store.userSelectedAccount.username}}</span> -->
                                                    </div>
                                                </div>
                                                <!-- v-if="store.userTradingAccountsList && store.userTradingAccountsList.length && store.userTradingAccountsList.length > 1" -->
                                                <span><vue-feather class="icon w-16 me-2" :type="changeAccount?'chevron-up':'chevron-down'"></vue-feather></span>
                                            </a>
                                            <div class="dropdown_menu maxAuto dropSelect box-shadow-full p-2" :class="changeAccount?'show':''">
                                                <p class="midgray mb-2 medium f-14" v-if="getTradingAccountWise('LIVE').length > 0">{{$t('head.text5')}}</p>
                                                <label class="cutomChechBox radio" v-if="getTradingAccountWise('LIVE').length > 1">
                                                    <div class="flex-between align-items-center px-2">
                                                        <div class="d-flex align-items-center">
                                                            <div class="name">
                                                                <span class="mb-0 f-14 line-1 darkHeader">{{$t('head.text6')}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input type="radio" name="radio" :value="'ALL_LIVE'" :checked="store.allTypeAccount == 'ALL_LIVE'" @change="changeBroker('ALL_LIVE')" /> 
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="cutomChechBox radio" v-for="account,key in getTradingAccountWise('LIVE')" :key="key">
                                                    <div class="flex-between align-items-center px-2">
                                                        <div class="d-flex align-items-center">
                                                            <span class="ms-0" v-if="account.brokerLogo"><v-lazy-image style="width:24px;" :src="static_vars.imagesURL+'/'+account.brokerLogo" :alt="$t('header.title7')" /></span>
                                                            <div class="name">
                                                                <span class="mb-0 f-14 line-1 darkHeader d-block" :title="account.isLeader ? (account.providerName || account.username) : (account.username || '')">{{account.isLeader ? (account.providerName || account.username) : account.username || ''}}</span>
                                                                <!-- Account Status -->
                                                                <!-- <span class="bg-secondary text-white f-11 d-inline-block br-12 px-2" v-if="account.connectivityStatus">{{account.connectivityStatus}}</span> -->
                                                            </div>
                                                        </div>
                                                        <span class="f-12 midgray">{{getAccountSymbol(account)}}{{parseFloat(account.balance).toFixed(2) || 0}}</span>
                                                    </div>
                                                    <input type="radio" name="radio" :value="account.zuluAccountId" @change="changeBroker(account)" :checked="store.userSelectedAccount.zuluAccountId == account.zuluAccountId"/> 
                                                    <span class="checkmark"></span>
                                                </label>
                                                <p class="midgray mb-2 medium f-14" v-if="getTradingAccountWise('DEMO').length > 0">{{$t('head.text7')}}</p>
                                                <label class="cutomChechBox radio" v-if="getTradingAccountWise('DEMO').length > 1">
                                                    <div class="flex-between align-items-center px-2">
                                                        <div class="d-flex align-items-center">
                                                            <div class="name">
                                                                <span class="mb-0 f-14 line-1 darkHeader">{{$t('head.text8')}}</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <input type="radio" name="radio" :value="'ALL_DEMO'" :checked="store.allTypeAccount == 'ALL_DEMO'" @change="changeBroker('ALL_DEMO')" /> 
                                                    <span class="checkmark"></span>
                                                </label>
                                                <label class="cutomChechBox radio" v-for="account,key in getTradingAccountWise('DEMO')" :key="key">
                                                    <div class="flex-between align-items-center px-2">
                                                        <div class="d-flex align-items-center">
                                                            <span class="ms-0" v-if="account.brokerLogo"><v-lazy-image style="width:24px;" :src="static_vars.imagesURL+'/'+account.brokerLogo" :alt="$t('header.title7')" /></span>
                                                            <div class="name">
                                                                <span class="mb-0 f-14 line-1 darkHeader" :title="account.isLeader ? (account.providerName || account.username) : (account.username || '')">{{account.isLeader ? (account.providerName || account.username) : account.username || ''}}</span>
                                                                <!-- <span class="f-11 d-block midgray">{{account.name || ''}}</span> -->
                                                            </div>
                                                        </div>
                                                        <span class="f-12 midgray">{{getAccountSymbol(account)}}{{parseFloat(account.balance).toFixed(2) || 0}}</span>
                                                    </div>
                                                    <input type="radio" name="radio" :value="account.zuluAccountId" @change="changeBroker(account)" :checked="store.userSelectedAccount.zuluAccountId == account.zuluAccountId"/> 
                                                    <span class="checkmark"></span>
                                                </label>
                                            </div>
                                        </li>
                                        <li @click="openMenu=false">
                                            <router-link :to="{path : '/dashboard/personal-info'}" class="sortLink d-flex align-items-center">
                                                <span class="sortImg"><v-lazy-image src="/assets/images/user-check.svg" :alt="$t('header.title8')" /></span>
                                                <span class="medium">{{$t('head.text9')}} </span>
                                            </router-link>
                                        </li>
                                        <li @click="openMenu=false">
                                            <router-link class="sortLink d-flex align-items-center" :to="{path : '/dashboard/trading-accounts'}">
                                                <span class="sortImg"><v-lazy-image src="/assets/images/menuDrop1@3x.png" :alt="$t('header.title8')" /></span>
                                                <span class="medium"> {{$t('head.text10')}} </span>
                                            </router-link>
                                        </li>
                                        <li class="d-flex align-items-center" @click="openMenu=false">
                                            <router-link class="sortLink d-flex align-items-center me-2" :to="{path : '/dashboard/subscription'}" :class="[{'disabled' : !store.customerDetail.completed || store.customerDetail.requiredActions || !COMMON.liveAccounts()}]">
                                                <span class="sortImg"><v-lazy-image src="/assets/images/menuDrop2@3x.png" :alt="$t('header.title9')" /></span>
                                                <span class="medium">{{$t('head.text11')}}</span>
                                            </router-link>
                                            <!-- <span class="veiwPLan d-flex align-items-center" v-if="Object.keys(store.subscriptionSelected).length">
                                                <i class="me-1 f-9 fa fa-circle" aria-hidden="true"></i>
                                                <span v-if="store.subscriptionSelected.months==1">Basic</span>
                                                <span v-else-if="store.subscriptionSelected.months==3">Advanced</span>
                                                <span v-else-if="store.subscriptionSelected.months==6">Premium</span>
                                                <span v-else-if="store.subscriptionSelected.months==12">Unlimited</span>
                                                <span v-else-if="store.subscriptionSelected.months==0">No Plan</span>
                                            </span> -->
                                        </li>
                                        <li @click="openMenu=false">
                                            <router-link class="sortLink d-flex align-items-center" :to="{path : '/dashboard/my-posts'}">
                                                <span class="sortImg"><v-lazy-image src="/assets/images/myPost.svg" :alt="$t('header.title10')" /> </span>
                                                <span class="medium">{{(Object.keys(store.userDetail).length && store.userDetail.firstName)? store.userDetail.firstName : 'My'}} {{$t('head.text12')}}</span>
                                            </router-link>
                                        </li>
                                        <!-- <li @click="openMenu=false">
                                            <router-link class="sortLink d-flex align-items-center" :to="{path : '/dashboard/trade-history'}">
                                                <span class="sortImg"><v-lazy-image src="/assets/images/settings/tradehistory_icon.svg" :alt="$t('changes.text34')" /> </span>
                                                <span class="medium">{{$t('head.text26')}}</span>
                                            </router-link>
                                        </li>
                                        <li @click="openMenu=false">
                                            <router-link class="sortLink d-flex align-items-center" :to="{path : '/dashboard/account'}">
                                                <span class="sortImg"><v-lazy-image src="/assets/images/settings/dropUserAcc.svg" :alt="$t('changes.text34')" /> </span>
                                                <span class="medium">{{$t('changes.text46')}}</span>
                                            </router-link>
                                        </li> -->
                                        <li @click="openMenu=false">
                                            <router-link class="sortLink d-flex align-items-center" :to="{path : '/dashboard/notifications'}">
                                                <span class="sortImg"><v-lazy-image src="/assets/images/settingsTabs.svg" :alt="$t('changes.text34')" /> </span>
                                                <span class="medium">{{$t('changes.text34')}}</span>
                                            </router-link>
                                        </li>
                                        <li @click="openMenu=false" v-if="store.isLeaderList?.length">
                                            <router-link class="sortLink d-flex align-items-center" :to="{name : 'leaderWidget'}">
                                                <span class="sortImg"><v-lazy-image src="/assets/images/menu-widget.svg" :alt="$t('head.text25')" /> </span>
                                                <span class="medium">{{$t('head.text25')}}</span>
                                            </router-link>
                                        </li>
                                        <li @click="openMenu=false">
                                            <router-link class="sortLink d-flex align-items-center" :to="{path : '/rewards'}">
                                                <span class="sortImg"><v-lazy-image src="/assets/images/menuDrop4@3x.png" :alt="$t('header.title12')" /> </span>
                                                <span class="medium">{{$t('head.text14')}}</span>
                                            </router-link>
                                        </li>
                                        <li @click="openMenu=false">
                                            <router-link class="sortLink d-flex align-items-center" :to="{path : '/dashboard/change-password'}">
                                                <span class="sortImg"><v-lazy-image src="/assets/images/passwordSqure.svg" :alt="$t('header.title11')" /> </span>
                                                <span class="medium">{{$t('head.text13')}}</span>
                                            </router-link>
                                        </li>
                                        <li @click="openMenu=false">
                                            <router-link class="sortLink d-flex align-items-center" :to="{path : '/dashboard/need-help'}">
                                                <span class="sortImg"><v-lazy-image src="/assets/images/needHelp.svg" :alt="$t('header.title12')" /> </span>
                                                <span class="medium">{{$t('head.text15')}}</span>
                                            </router-link>
                                        </li>
                                        <!-- <li class="d-flex align-items-center justify-content-between">
                                            <a href="javascript:void(0)" class="sortLink d-flex align-items-center">
                                                <span class="sortImg"><v-lazy-image src="/assets/images/lightDark.svg" alt="Rewards" /> </span>
                                                <span class="medium">Dark theme</span>
                                            </a>
                                            <label class="switch">
                                                <input type="checkbox" id="theme-toggle" name="leader" value="" :checked="currentTheme=='dark'" @click="changeTheme()">
                                                <span class="slider round"></span>
                                            </label>
                                        </li> -->
                                        <li :class="{'disabled' : store.customerDetail?.readOnly}" @click="openMenu=false" v-if="store.questionariesStatus?.status == 'SUBMITTED'">
                                            <a :href="static_vars.domainURL+'register/sutaibility'" class="sortLink d-flex align-items-center">
                                                <span class="sortImg"><v-lazy-image src="/assets/images/suitabilityTest.svg" :alt="$t('changes.text35')" /> </span>
                                                <span class="medium">{{$t('changes.text35')}}</span>
                                            </a>
                                        </li>
                                        <li @click="openMenu=false">
                                            <a @click="store.logout()" class="sortLink d-flex align-items-center pb-0" href="javascript:void(0)">
                                                <span class="sortImg"><v-lazy-image src="/assets/images/menuDrop6@3x.png" :alt="$t('header.title13')" /></span>
                                                <span class="medium">{{$t('head.text16')}}</span>
                                            </a>
                                        </li>
                                    </ul>
                                    <ul class="usefulLink mb-2 py-1">
                                        <li class="d-flex align-items-center">
                                            <router-link to="/terms-of-service" class="ps-0" target="_blank">{{$t('head.text17')}}</router-link> · 
                                            <router-link to="/privacy-policy" target="_blank">{{$t('head.text18')}}</router-link>· 
                                            <router-link to="/risk-disclaimer" target="_blank">{{$t('head.text19')}}</router-link>
                                        </li>
                                        <li class="midgray f-11">{{$t('head.text20')}}</li>
                                    </ul>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </nav>
        <!-- <expandMenu v-if="showOnlyRoutes()" && !isLeaderAccount()/>  -->
        <!-- <LeaderMenu v-if="showOnlyRoutes() && isLeaderAccount()" /> -->
        </header>
    </div>
</template>

<script>
import $ from "jquery";
import { myStore } from "@/store/pinia-store";
// import expandMenu from './expand-menu'
//import LeaderMenu from './leader-menu'
import Search from '../global-search/search'
import moment from 'moment'
import Market from "@/views/dashboard-new/pages/without-leader/markets";
import LoginFeed from '@/store/login-stomp'
// import PriceFeed from "@/store/stomp";
export default {
    setup() {
        const store = myStore();
        return { store };
    },
    data() {
        return {
            changeAccount:false,
            openMenu:false,
            notification:false,
            globalSearch : '',
            openWatchlist: false,
            currentTheme: '',
            showHumbugerMenu: false
        };
    },
    components:{
        //expandMenu,
        Search,
        Market,
        //LeaderMenu
    },
    methods: {
        isLeaderAccount(){
                if(this.store.allTypeAccount){
                    return false
                } else if(Object.keys(this.store.userSelectedAccount).length){
                    if(this.store.userSelectedAccount.isLeader && this.store.userSelectedAccount.providerStatus=='APPROVED'){
                        return true
                    }else{
                        return false
                    }
                }
            },
        htmlToText(text){
            if(text){
                // Create a new div element
                var tempDivElement = document.createElement("div");

                // Set the HTML content with the given value
                tempDivElement.innerHTML = text;

                // Retrieve the text property of the element 
                return tempDivElement.textContent || tempDivElement.innerText || "";
            }else{
                return ""
            }
        },
        showText(message){
            if(message){
                if(message.length > 150){
                    return `${this.htmlToText(message).slice(0,150)}...`
                }else{
                    return this.htmlToText(message)
                }
            }
        },
        fomatDate(date){
            if(date){
                let d = moment(date)
                let tz = (new Date().getTimezoneOffset());
                d.add(tz, 'minutes')
                return moment(d).format('DD MMM YYYY HH:mm a')
            }
        },
        getAccountSymbol(account){
            if(Object.keys(account).length && Object.keys(this.store.allCurrencySymbols).length){
                return this.store.allCurrencySymbols[account.baseCurrencyId].htmlSymbol
            }
        },
        getTradingAccountWise(type){
            if(this.store.userTradingAccountsList.length){
                if(type == 'DEMO'){
                    return this.store.userTradingAccountsList.filter( i => i.demo === true)
                }else if(type == 'LIVE'){
                     return this.store.userTradingAccountsList.filter( i => i.demo === false)
                }
            }else{
                return []
            }
        },
        toggleBodyClass(addRemoveClass, className) {
            const el = document.body;
            if (addRemoveClass === "addClass") {
                el.classList.add(className);
            } else {
                el.classList.remove(className);
            }
        },
        addClass(getData) {
            const el = document.body;
            if (getData == "remove") {
                if (el.classList.contains("toggle")) {
                el.classList.remove("toggle");
                }
            } else {
                if (el.classList.contains("toggle")) {
                el.classList.remove("toggle");
                } else {
                el.classList.add("toggle");
                }
            }
            this.showHumbugerMenu = false
        },
        gotoRoute(){
            if(this.$route.name == 'terms of service' || this.$route.name == 'privacy policy' || this.$route.name == 'risk disclaimer' || this.$route.name == 'automated trading' || this.$route.name == 'autoprotect account' || this.$route.name == 'about' || this.$route.name == 'affiliate' || this.$route.name == 'careers' || this.$route.name == 'trader'){
                this.$router.push('/')
            }else{
                this.$router.push('/')
            }
        },
        showSearch(){
            if(this.$route.name == 'traders' || this.$route.name == 'view_all_leader' || this.$route.name == 'traderDetail' || this.$route.name == 'rates' || this.$route.name == 'market_details' || this.$route.name == 'all_market'){
                return true
            }else{
                return false
            }
        },
        showOnlyRoutes(){
             if(this.$route.name == 'traders' || this.$route.name == 'view_all_leader' || this.$route.name == 'rates' || this.$route.name == 'market_details' || this.$route.name == 'all_market' || this.$route.name == 'dashboard_portfolio' || this.$route.name == 'socialFeed'){
                return true
            }else{
                return false
            }
        },
        changeBroker(list){
            this.changeAccount = false
            if(list == 'ALL_DEMO' || list == 'ALL_LIVE'){
                this.store.$patch({'allTypeAccount' :list})
                if(this.$route.name == 'traderDetail' || this.$route.name == 'simulation' || this.$route.name == 'market_details'){
                    let data = [];
                    let json = {
                        'from' : moment().subtract(1,'days').format('YYYY-MM-DD'),
                        'to' : moment().format('YYYY-MM-DD')
                    }
                    this.store.getMarketCaterogyWise(json,false)
                    if(this.store.allTypeAccount == 'ALL_LIVE'){
                        data = this.store.userTradingAccountsList.filter(i => i.demo === false)
                        if(data.length){
                            this.store.$patch({'allTypeAccount' :null})
                            this.store.$patch({'userSelectedAccount' : data[0]})
                            this.store.callCurrencyPairsList({}, false,  data[0].feedGroupId);
                        }
                    }else if(this.store.allTypeAccount == 'ALL_DEMO'){
                        data = this.store.userTradingAccountsList.filter(i => i.demo === true)
                        if(data.length){
                            this.store.$patch({'allTypeAccount' :null})
                            this.store.$patch({'userSelectedAccount' : data[0]})
                            this.store.callCurrencyPairsList({}, false,  data[0].feedGroupId);
                        }
                    }
                    this.store.getUserDashboardData({},true,'no-change','10000')
                    if(data.length){
                        let form = {
                        'targetBrokerAccountId' : data[0].brokerAccountId,
                        'page' : 0,
                        'size' : 10,
                        'sort' : 'desc',
                        };
                        this.store.userNotifications(form,false)
                        if(this.$route.name == 'allNotifications'){
                            let form1 = {
                                'targetBrokerAccountId' : data[0].brokerAccountId,
                                'page' : 0,
                                'size' : 10,
                                'sort' : 'desc',
                                'dateTo' : moment().format('YYYY-MM-DD'),
                                'dateFrom' :moment().subtract(1,'year').format('YYYY-MM-DD')
                            };
                            this.store.userNotifications(form1,false)
                        }
                    }
                    LoginFeed.getInstance().unsubscribe();
                }else{
                    this.store.$patch({'allTypeAccount' :list})
                    //this.store.getUserDashboardData({},true,'','10000')
                    let data = [];
                    let json = {
                        'from' : moment().subtract(1,'days').format('YYYY-MM-DD'),
                        'to' : moment().format('YYYY-MM-DD')
                    }
                    this.store.getMarketCaterogyWise(json,false)
                    if(this.store.allTypeAccount == 'ALL_LIVE'){
                        data = this.store.userTradingAccountsList.filter(i => i.demo === false)
                        if(data.length){
                            this.store.$patch({'allTypeAccount' :'ALL_LIVE'})
                            this.store.$patch({'userSelectedAccount' : {}})
                            this.store.callCurrencyPairsList({}, false,  data[0].feedGroupId);
                        }
                    }else if(this.store.allTypeAccount == 'ALL_DEMO'){
                        data = this.store.userTradingAccountsList.filter(i => i.demo === true)
                        if(data.length){
                            this.store.$patch({'allTypeAccount' :'ALL_DEMO'})
                            this.store.$patch({'userSelectedAccount' : {}})
                            this.store.callCurrencyPairsList({}, false,  data[0].feedGroupId);
                        }
                    }
                    this.store.getUserDashboardData({},true,'no-change','10000')
                    if(data.length){
                        let form = {
                        'targetBrokerAccountId' : data[0].brokerAccountId,
                        'page' : 0,
                        'size' : 10,
                        'sort' : 'id,desc',
                        };
                        this.store.userNotifications(form,false)
                        if(this.$route.name == 'allNotifications'){
                            let form1 = {
                                'targetBrokerAccountId' : data[0].brokerAccountId,
                                'page' : 0,
                                'size' : 10,
                                'sort' : 'id,desc',
                                'dateTo' : moment().format('YYYY-MM-DD'),
                                'dateFrom' :moment().subtract(1,'year').format('YYYY-MM-DD')
                            };
                            this.store.userNotifications(form1,false)
                        }
                    }
                    LoginFeed.getInstance().unsubscribe();
                }
                this.store.getProviderOpenTrades({},true)
            }else{
                this.store.$patch({'allTypeAccount' :null})
                this.store.$patch({'userSelectedAccount' : list})
                let json = {
                    'from' : moment().subtract(1,'days').format('YYYY-MM-DD'),
                    'to' : moment().format('YYYY-MM-DD')
                }
                this.store.getMarketCaterogyWise(json,false)
                this.store.getUserDashboardData({},true,'','10000')
                this.store.callCurrencyPairsList({}, false, this.store.userSelectedAccount.feedGroupId);
                let form = {
                    'targetBrokerAccountId' : this.store.userSelectedAccount.brokerAccountId,
                    'page' : 0,
                    'size' : 10,
                    'sort' : 'id,desc',
                };
                this.store.userNotifications(form,false)
                if(this.$route.name == 'allNotifications'){
                    let form1 = {
                        'targetBrokerAccountId' : this.store.userSelectedAccount.brokerAccountId,
                        'page' : 0,
                        'size' : 10,
                        'sort' : 'id,desc',
                        'dateTo' : moment().format('YYYY-MM-DD'),
                        'dateFrom' :moment().subtract(1,'year').format('YYYY-MM-DD')
                    };
                    this.store.userNotifications(form1,false)
                }
                LoginFeed.getInstance().unsubscribe();
                //this.store.GetCopyTraders({},false)
                if(this.$route.name == 'dashboard_portfolio'){
                    if(this.store.dashboardTradesTab == 'open'){
                        this.store.getProviderOpenTrades({},true)
                    }else if(this.store.dashboardTradesTab == 'pending'){
                        this.store.getProviderPendingTrades({page:0,size:10},true,this.store.userSelectedAccount.brokerAccountId)
                    }else if(this.store.dashboardTradesTab == 'history'){
                        this.store.getProviderHistoryTrades({page:0,size:10},true,this.store.userSelectedAccount.brokerAccountId)
                    }
                }else{
                    this.store.getProviderOpenTrades({},true)
                }
            }
            
        },
        changeTheme(){
            if(this.currentTheme=='light'){
                this.currentTheme = 'dark'
            } else {
                this.currentTheme = 'light'
            }
        },
    },
    mounted() {
        var header = document.getElementById("myHeader");
            window.addEventListener("scroll", () => {
            if (window.pageYOffset > header.offsetTop) {
                header.classList.add("sticky");
            } else {
                header.classList.remove("sticky");
            }
        });
      let _that = this 
        $(document).on("click", function (event) {
            if (!$(event.target).closest(".showClass").length) {
                _that.openMenu = false
                _that.changeAccount = false
                _that.notification = false
                _that.openWatchlist = false
            }
            if (!$(event.target).closest(".clickOutRemove").length) {
                _that.showHumbugerMenu = false
            }
        });
        var toggle = document.getElementById("theme-toggle");
        var storedTheme = localStorage.getItem('theme') || (window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
        if (storedTheme) {
            document.documentElement.setAttribute('data-theme', storedTheme)
        }
        if(toggle){
            toggle.onclick = function() {
                var currentTheme = document.documentElement.getAttribute("data-theme");
                var targetTheme = "light";
                if (currentTheme === "light") {
                    targetTheme = "dark";
                }
                document.documentElement.setAttribute('data-theme', targetTheme)
                localStorage.setItem('theme', targetTheme);
            };
        }
        this.currentTheme = localStorage.getItem('theme')
    },
};
</script>
