<style scoped>
    .footer-social-icon .fa {
        margin: inherit !important;
    }
</style>
<template>
    <footer class="mainFooter bodyBG">
        <div class="container-fluid">
            <div class="row justify-content-between">
                <!-- <div class="col-12 col-lg-3">
               <div class="content">
                   <h6 class="d-lg-none" v-on:click="isHidden1 = !isHidden1" v-bind:class="[{ active: isHidden1 }]">Blog</h6>
                   <ul class="footerList">
                       <li><router-link to="/register">Register</router-link></li>
                       <li><a href="javascript:void(0)">Supported Brokers</a></li>
                       <li><a href="javascript:void(0)">Blog</a></li>
                       <li><a href="javascript:void(0)">Forum</a></li>
                   </ul>
               </div>
               </div> -->
                <div class="col-12 col-xl-4">
                    <div class="d-flex flex-column h-100 justify-content-between calBOxed">
                        <div class="socialFooter">
                            <div class="footerLogo flex-between align-items-start mb-3">
                                <img class="lightTheme" width="180" height="50" src="/assets/images/logo.svg" :alt="$t('footer.text23')" :title="$t('footer.text24')" />
                                <img class="darkTheme d-none" width="180" height="50" src="/assets/images/whiteLogo.svg" :alt="$t('footer.text23')" :title="$t('footer.text24')" />
                                
                            </div>
                            <div class="zulutradeCopyright menutextBG p-3 pb-0 d-flex align-items-start justify-content-between">
                                <div class="downloadApp">
                                    <p class="text-white f-20 mb-md-4 mb-2">{{$t('footer.text2')}} <span class="hilighted fadebg">{{$t('footer.text3')}}</span></p>
                                    <p class="f-12 mb-1 lighter text-white">{{$t('footer.text4')}}</p>
                                    <div class="d-flex align-items-center">
                                        <a href="https://apps.apple.com/gr/app/zulutrade/id336913058" target="_blank" class="fadebg appStore text-white medium f-14 me-2">
                                            <img src="/assets/images/iosIcon.png" :alt="$t('footer.text25')" :title="$t('footer.text26')" />
                                            <span>{{$t('login.ios')}}</span>
                                        </a>
                                        <a href="https://play.google.com/store/apps/details?id=zulu.trade.app" target="_blank" class="fadebg appStore text-white medium f-14">
                                            <img src="/assets/images/androidIcon.png" :alt="$t('footer.text27')" :title="$t('footer.text28')" />
                                            <span>{{$t('login.android')}}</span>
                                        </a>
                                    </div>
                                </div>
                                <div class="mobileApp">
                                    <img class="d-block" src="/assets/images/zuluFooterImg.png" :alt="$t('footer.text29')" :title="$t('footer.text30')" />
                                </div>
                            </div>
                            <div class="rtSocial mt-4">
                                <p class="mb-2">{{$t('footer.text1')}}</p>
                                <ul class="socialList d-flex align-items-center justify-content-start">
                                    <li>
                                        <a class="d-block ps-0" href="https://www.facebook.com/zulutrade/" target="_blank" :title="$t('footer.text31')"><i class="fa fa-facebook" aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a class="d-block" href="https://twitter.com/ZuluTrade" target="_blank" :title="$t('footer.text32')"><i class="fa fa-twitter" aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a class="d-block" href="https://www.linkedin.com/company/zulutrade" target="_blank" :title="$t('footer.text33')"><i class="fa fa-linkedin" aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a class="d-block" href="https://www.youtube.com/user/zulutradechannel" target="_blank" title="$t('footer.text34')"><i class="fa fa-youtube-play" aria-hidden="true"></i></a>
                                    </li>
                                    <li>
                                        <a class="d-block" href="https://www.instagram.com/zulutrade/?hl=en" target="_blank">
                                            <img class="light" src="/assets/images/instagram.png" :alt="$t('footer.text35')" :title="$t('footer.text35')" width="24" height="24"/>
                                            <img class="dark d-none" src="/assets/images/instagram-w.png" :alt="$t('footer.text35')" :title="$t('footer.text35')" width="24" height="24"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="d-block" href="https://www.tiktok.com/@zulutrade" target="_blank">
                                            <img class="light" src="/assets/images/tiktok.png" :alt="$t('footer.text36')" :title="$t('footer.text36')" width="24" height="24"/>
                                            <img class="dark d-none" src="/assets/images/tiktok-w.png" :alt="$t('footer.text36')" :title="$t('footer.text36')" width="24" height="24"/>
                                        </a>
                                    </li>
                                    <li>
                                        <a class="d-block" href="https://www.reddit.com/r/Zulu_Trade/" target="_blank">
                                            <img class="light" src="/assets/images/reddit.png" :alt="$t('footer.text37')" :title="$t('footer.text37')" width="24" height="24"/>
                                            <img class="dark d-none" src="/assets/images/reddit-w.png" :alt="$t('footer.text37')" :title="$t('footer.text37')" width="24" height="24"/>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="copyRightZultarde">
                           <p class="f-14 midgray mb-0">©{{$t('footer.globalCR')}} {{BrandName()}}</p>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-xl-8">
                    <div class="row">
                        <div class="col-12 col-lg-4">
                            <div class="sndTop">
                                <h6 v-on:click="isHidden2 = !isHidden2" v-bind:class="[{ active: isHidden2 }]">{{$t('footer.text5')}}</h6>
                                <ul class="footerList">
                                    <li>
                                        <!-- <a href="/assets/pdf/legal/TOS-EU.pdf" target="_blank" v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId == 38">Terms Of Service</a>
                                        <a href="/assets/pdf/legal/TOS-EU.pdf" target="_blank" v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId == 1">Terms Of Service</a> -->
                                        <router-link to="/terms-of-service">{{$t('footer.text20')}}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/privacy-policy">{{$t('head.text18')}}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/risk-disclaimer">{{$t('head.text19')}}</router-link>
                                    </li>
                                     <li>
                                        <!-- <a href="/assets/pdf/legal/Conflicts_Of_Interest_Policy_ZULUTRADE.pdf" target="_blank" v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId == 38">Conflict of Interest Policy</a>
                                        <a href="/assets/pdf/legal/Conflicts_Of_Interest_Policy_ZULUTRADE.pdf" target="_blank" v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId == 1">Conflict of Interest Policy</a> -->
                                        <router-link to="/conflict-of-interest-policy">{{$t('footer.text6')}}</router-link>
                                    </li>
                                     <li>
                                        <!-- <a href="/assets/pdf/legal/Declaration_of_Consent_ZULUTRADE.pdf" target="_blank" v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId == 38">Declaration of Consent</a>
                                        <a href="/assets/pdf/legal/Declaration_of_Consent_ZULUTRADE.pdf" target="_blank" v-if="Object.keys(store.flavordata).length && store.flavordata.flavorId == 1">Declaration of Consent</a> -->
                                         <router-link to="/declaration-of-consent">{{$t('footer.text7')}}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/eula">{{$t('footer.text22')}}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="sndBottom">
                                <h6 v-on:click="isHidden3 = !isHidden3" v-bind:class="[{ active: isHidden3 }]">{{$t('footer.text8')}}</h6>
                                <ul class="footerList">
                                    <!-- <li><router-link to="/automated-trading">Automator</router-link></li> -->
                                    <li>
                                        <router-link to="/autoprotect-your-account">{{$t('footer.text9')}}</router-link>
                                    </li>
                                    <!-- <li><router-link to="/subscription">Subscription Plan</router-link></li> -->
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="sndTop">
                                <h6 v-on:click="isHidden4 = !isHidden4" v-bind:class="[{ active: isHidden4 }]">{{$t('header.tool')}}</h6>
                                <ul class="footerList">
                                    <li>
                                        <router-link :to="{name:'calculator'}">{{$t('header.text3')}}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/calendar">{{$t('header.text2')}}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/pricing">{{$t('header.text4')}}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/trading-signals"> Trading Signals</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/forex-trading">Forex Trading</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="sndBottom">
                                <h6 v-on:click="isHidden5 = !isHidden5" v-bind:class="[{ active: isHidden5 }]">{{$t('footer.text10')}}</h6>
                                <ul class="footerList">
                                    <!-- <li>
                                        <router-link to="/blog">Blog</router-link>
                                    </li> -->
                                    <li>
                                        <router-link to="/affiliate-guide">{{$t('header.affiliate')}}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/leader-guide">{{$t('footer.text11')}}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/user-guide">{{$t('footer.text12')}}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/introducing-zulutrade">{{$t('footer.text13')}} </router-link>
                                    </li>
                                    <li v-if="store.user.access_token">
                                        <router-link to="/feedback">{{$t('footer.text14')}}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/sitemap">{{$t('footer.text15')}}</router-link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12 col-lg-4">
                            <div class="sndTop">
                                <h6 v-on:click="isHidden6 = !isHidden6" v-bind:class="[{ active: isHidden6 }]">{{$t('footer.text16')}}</h6>
                                <ul class="footerList">
                                    <li>
                                        <router-link to="/about">{{$t('header.company')}} </router-link>
                                    </li>
                                    <!-- <li>
                                        <router-link to="/pricing">Pricing </router-link>
                                    </li> -->
                                    <!-- <li>
                                        <router-link to="/careers">Careers</router-link>
                                    </li> -->
                                </ul>
                            </div>
                            <div class="sndBottom">
                                <h6 v-on:click="isHidden7 = !isHidden7" v-bind:class="[{ active: isHidden7 }]">{{$t('header.partner')}}</h6>
                                <ul class="footerList">
                                    <li>
                                        <router-link to="/become-a-leader">{{$t('footer.text21')}}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/become-an-affiliate">{{$t('footer.text17')}}</router-link>
                                    </li>
                                    <li>
                                        <router-link to="/partnership-program">{{$t('footer.text18')}}</router-link>
                                    </li>
                                </ul>
                            </div>
                            <div class="sndBottom">
                                <h6 v-on:click="isHidden8 = !isHidden8" v-bind:class="[{ active: isHidden8 }]">{{$t('footer.text38')}}</h6>
                                <ul class="footerList">
                                    <li>
                                        <a class="d-flex align-items-center" target="_blank" href="tel:+30 2130117499"><vue-feather size="16" class="me-2" type="phone-call"></vue-feather> +30 2130117499</a>
                                    </li>
                                    <li>
                                        <a class="d-flex align-items-center" target="_blank" href="mailto:support@zulutrade.com"><vue-feather size="16" class="me-2" type="mail"></vue-feather> support@zulutrade.com</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="disclamer mt-1" v-if="Object.keys(store.flavordata).length">
                                <p class="f-14 mb-2 medium darkbluecolor">{{$t('footer.text19')}}</p>
                                <div v-if="!store.flavordata?.isEu">
                                    <div class="list-unstyled">
                                        <p>{{$t('TOS.gText144')}}</p>
                                        <p>{{$t('TOS.gText145')}}</p>
                                        <ul class="dotList mb-2 ms-3">
                                            <li>{{$t('TOS.gText146')}}</li>
                                            <li>{{$t('TOS.gText147')}}</li>
                                            <li>{{$t('TOS.gText148')}}</li>
                                            <li>{{$t('TOS.gText149')}}</li>
                                        </ul>
                                        <p>{{$t('TOS.gText150')}}</p>
                                        <p>{{$t('TOS.gText151')}}</p>
                                        <p>{{$t('TOS.gText152')}} <router-link class="underline" to="/risk-disclaimer" style="color: #a16d2e;">{{$t('TOS.gText153')}}</router-link></p>
                                    </div>
                                </div>
                                <div v-if="store.flavordata?.isEu">
                                    <!-- <p class="f-14 midgray">©{{$t('footer.euCR')}}</p> -->
                                    <ul class="list-unstyled">
                                        <p>{{$t('TOSEU.eText272')}}</p>
                                        <p>{{$t('TOSEU.eText273')}}</p>
                                        <ul class="dotList mb-2 ms-3">
                                            <li>{{$t('TOSEU.eText274')}}</li>
                                            <li>{{$t('TOSEU.eText275')}}</li>
                                            <li>{{$t('TOSEU.eText276')}}</li>
                                            <li>{{$t('TOSEU.eText277')}}</li>
                                        </ul>
                                        <p>{{$t('TOSEU.eText278')}}</p>
                                        <p>{{$t('TOSEU.eText279')}}</p>
                                        <p>{{$t('TOSEU.eText280')}} <router-link class="underline" to="/risk-disclaimer" style="color: #a16d2e;">{{$t('TOSEU.eText281')}}</router-link></p>
                                    </ul>
                                </div>
                                <!-- <p class="mb-1 f-14 darkbluecolor" v-if="store.flavordata.flavorId == 38">{{$t('footer.euService')}}</p>
						<p>
							{{(store.flavordata.flavorId == 1) ? $t('footer.globalFooter') : $t('footer.euFooter')}}
							<router-link to="/risk-disclaimer" style="color:#A16D2E"> full disclaimer</router-link>
						</p> -->
                                <!-- <p class="mb-0">
							Trading spot currencies involves substantial risk and there is always the potential for loss. Your trading results may vary. Because the risk factor is high in the foreign exchange market trading, only genuine "risk" funds should be used in such trading. If you do not have the extra capital that you can afford to lose, you should not trade in the foreign exchange market. Forex Brokers... <router-link to="/risk-disclaimer" style="color:#A16D2E"> full disclaimer</router-link>.
							</p> -->
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
<script>
    import { myStore } from "@/store/pinia-store";
    export default {
        setup() {
            const store = myStore();
            return { store };
        },
        data() {
            return {
                isHidden1: false,
                isHidden2: false,
                isHidden3: false,
                isHidden4: false,
                isHidden5: false,
                isHidden6: false,
                isHidden7: false,
                isHidden8: false,
            };
        },
        methods:{
            BrandName(){
                return window.location.hostname.split('.')[0].toUpperCase();
            }
        }
    };
</script>
