import { createRouter, createWebHistory } from "vue-router";
import { myStore } from "@/store/pinia-store.ts";
import static_vars from '@/staticVars'
//Entry point
import Index from "@/views/index.vue";

const routes = [
    {
        path: "/moble-faqs",
        name: "moble faqs",
        component: () => import("@/views/mobile/mobile_faq.vue"),
        requiresAuth: false,
    },
    {
        path: "/mobile-risk-disclaimer",
        name: "mobile risk disclaimer",
        component: () => import("@/views/mobile/mobile-risk-disclaimer.vue"),
        requiresAuth: false,
    },
    // {
    //     path: "/mobile-redirect/",
    //     name: "mobileRedirect",
    //     component: () => import("@/views/mobile/mobile-redirect.vue"),
    //     requiresAuth: false,
    // },
    {
        path: "/widgets/:widget",
        name: "widgets",
        component: () => import("@/views/widgets/index.vue"),
        requiresAuth: false,
    },
    {
        path: "/widgets/banner/:type",
        name: "banner",
        component: () => import("@/views/widgets/banner.vue"),
        requiresAuth: false,
    },
    {
        path: "/widgets/trader-info/:id",
        name: "traderInfo",
        component: () => import("@/views/widgets/trader-info.vue"),
        requiresAuth: false,
    },
    {
        path: "/chart/:id",
        name: "chart",
        component: () => import("@/views/trader/chart.vue"),
        requiresAuth: false,
    },
    {
        path: "/advanced/:id",
        name: "advanced",
        component: () => import("@/views/trader/advanced.vue"),
        requiresAuth: false,
    },
    {
        path: "/market/:id",
        name: "mobileMarket",
        component: () => import("@/views/markets/mobile-market/market-chart.vue"),
        requiresAuth: false,
    },
    {
        path: "/:pathMatch(.*)*",
        component: () => import("@/components/shared/page-not-found.vue"),
        requiresAuth: false,
    },

    {
        path: "/login",
        name: "login",
        component: () => import("@/views/auth/login.vue"),
        requiresAuth: false,
    },
    {
        path: "/widgets/register",
        name: "register",
        component: () => import("@/views/auth/register.vue"),
        requiresAuth: false,
    }, 
    {
        path: "/widgets/performance",
        name: "Widgetperformance",
        component: () => import("@/views/widgets/performance.vue"),
        requiresAuth: false,
    },
    {
        path: "/",
        component: Index,
        meta: {
            requiresAuth: true,
        },

        children: [
            {
                path: "/",
                name: "index",
                component: () => import("@/views/home/home.vue"),
                requiresAuth: false,
            },
            {
                path: "/about",
                name: "about",
                component: () => import("@/views/about/about.vue"),
                requiresAuth: false,
            },
            // {
            //     path: "/blog",
            //     name: "blog",
            //     component: () => import("@/views/blog/blog.vue"),
            //     requiresAuth: false,
            // },
            // {
            //     path: "/careers",
            //     name: "careers",
            //     component: () => import("@/views/about/career.vue"),
            //     requiresAuth: false,
            // },
            {
                path: "/become-an-affiliate",
                name: "affiliate",
                component: () => import("@/views/about/become-an-affiliate.vue"),
                requiresAuth: false,
            },
            {
                path: "/affiliate-guide",
                name: "affiliateGuide",
                component: () => import("@/views/about/affiliate-guide.vue"),
                requiresAuth: false,
            },
            {
                path: "/introducing-zulutrade",
                name: "introducingZuluTrade",
                component: () => import("@/views/about/introducing-zulutrade.vue"),
                requiresAuth: false,
            },
            {
                path: "/leader-guide",
                name: "traderGuide",
                component: () => import("@/views/about/leader-guide.vue"),
                requiresAuth: false,
            },
            // {
            //     path: "/become-a-trader",
            //     name: "trader",
            //     component: () => import("@/views/about/become-a-trader"),
            //     requiresAuth: false,
            // },
            {
                path: "/become-a-leader",
                name: "leader",
                component: () => import("@/views/about/become-a-leader"),
                requiresAuth: false,
            },
            {
                path: "/partnership-program",
                name: "partnership",
                component: () => import("@/views/about/partnership-program.vue"),
                requiresAuth: false,
            },
            {
                path: "/zulutrade-data",
                name: "Zulutrade Data",
                component: () => import("@/views/about/zulutrade-data.vue"),
                requiresAuth: false,
            },
            {
                path: "/user-guide",
                name: "userGuideTrade",
                component: () => import("@/views/about/user-guide.vue"),
                requiresAuth: false,
            },
            // {
            //     path: "/new-update-V2",
            //     name: "newDailyWeeklyEmails",
            //     component: () => import("@/views/landing/new-update-V2.vue"),
            //     requiresAuth: false,
            // },
            {
                path: "/help-center",
                name: "help center",
                component: () => import("@/views/learn/help-center.vue"),
                requiresAuth: false,
            },
            {
                path: "/pricing",
                name: "pricingPage",
                component: () => import("@/views/pricing/pricing.vue"),
                requiresAuth: false,
            },
            {
                path: "/feedback",
                name: "feedBack",
                component: () => import("@/views/about/feedback.vue"),
                requiresAuth: false,
            },
            // {
            //     path: "/settings",
            //     name: "settings",
            //     component: () => import("@/views/settings/settings.vue"),
            //     requiresAuth: false,
            // },
            // { 
            //     path: "/forex-trading",
            //     name: "forex trading",
            //     component: () => import("@/views/markets/forex-trading.vue"),
            //     requiresAuth: false,
            // },
            // {
            //     path: "/stock-trading",
            //     name: "stock trading",
            //     component: () => import("@/views/markets/stock-trading.vue"),
            //     requiresAuth: false,
            // }, 
            // {
            //     path: "/cryptocurrency-trading",
            //     name: "cryptocurrency trading",
            //     component: () => import("@/views/markets/cryptocurrency-trading.vue"),
            //     requiresAuth: false,
            // },
            // {
            //     path: "/commodities-trading",
            //     name: "commodities trading",
            //     component: () => import("@/views/markets/commodities-trading.vue"),
            //     requiresAuth: false,
            // },
            // {
            //     path: "/cryptocurrency-trading",
            //     name: "cryptocurrency trading",
            //     component: () => import("@/views/markets/cryptocurrency-trading.vue"),
            //     requiresAuth: false,
            // },
            // {
            //     path: "/index-trading",
            //     name: "index trading",
            //     component: () => import("@/views/markets/index-trading.vue"),
            //     requiresAuth: false,
            // },
            {
                path: "/markets",
                name: "rates",
                component: () => import("@/views/markets/markets.vue"),
                requiresAuth: false,
                props: true,
            },
            // {
            //     path: "/all-markets",
            //     name: "allmarkets",
            //     component: () => import("@/views/markets/markets.vue"),
            //     requiresAuth: false,
            // },
            {
                path: "/market-details/:id/:currency",
                name: "market_details",
                component: () => import("@/views/markets/market_details.vue"),
                requiresAuth: false,
            },
            {
                path: "/calendar",
                name: "calendar",
                component: () => import("@/views/markets/calendar.vue"),
                requiresAuth: false,
            },
            // {
            //     path: "/calculator",
            //     name: "calculator",
            //     component: () => import("@/views/markets/calculator/calculator.vue"),
            //     requiresAuth: false,
            // },
            {
                path: "/automated-trading",
                name: "automated trading",
                component: () => import("@/views/features/automated-trading.vue"),
                requiresAuth: false,
            },
            {
                path: "/autoprotect-your-account",
                name: "autoprotectAccount",
                component: () => import("@/views/features/autoprotect-your-account.vue"),
                requiresAuth: false,
            },
            {
                path: "/trader/:id/trading",
                name: "traderDetail",
                component: () => import("@/views/trader/trader-detail.vue"),
                requiresAuth: false,
            },
            {
                path: "/leaders",
                name: "traders",
                component: () => import("@/views/trader/top-traders.vue"),
                requiresAuth: false,
            },
            {
                path: "/simulation",
                name: "simulation",
                component: () => import("@/views/simulation/simulation.vue"),
                requiresAuth: false,
            },
            // {
            //     path: "/traders/winning/:timeframe",
            //     name: "allTrader",
            //     component: () => import("@/views/trader/all-traders.vue"),
            //     requiresAuth: false,
            // },
            {
                path: "/social-feed",
                name: "socialFeed",
                component: () => import("@/views/social-feed/social-feed.vue"),
                requiresAuth: false,
            },
            // {
            //     path: "/social-v2",
            //     name: "socialFeedv2",
            //     component: () => import("@/views/trader/social/social-v2.vue"),
            //     requiresAuth: false,
            // },
            {
                path: "/newsfeed/share",
                name: "socialFeedDetail",
                component: () => import("@/views/social-feed/feed-detail.vue"),
                requiresAuth: false,
            },
            {
                path: "/news-and-media",
                name: "newsAndMedia",
                component: () => import("@/views/news/news-and-media.vue"),
                requiresAuth: false,
            },
            {
                path: "/compare",
                name: "Compare",
                component: () => import("@/views/trader/compare/compare.vue"),
                requiresAuth: false,
            },
            // {
            //     path: "/tutorials",
            //     name: "tutorials",
            //     component: () => import("@/views/learn/tutorial.vue"),
            //     requiresAuth: false,
            // },
            {
                path: "/disclaimer",
                name: "disclaimer",
                requiresAuth: false,
            },
            {
                path: "/help-center-questions",
                name: "help center questions",
                component: () => import("@/views/learn/help-center-questions.vue"),
                requiresAuth: false,
            },
            {
                path: "/terms-of-service",
                name: "terms of service",
                component: () => import("@/views/terms/terms-of-service.vue"),
                requiresAuth: false,
            },
            {
                path: "/conflict-of-interest-policy",
                name: "conflict_of_intrest_policy",
                component: () => import("@/views/terms/conflict-of-intrest-policy.vue"),
                requiresAuth: false,
            },
            {
                path: "/declaration-of-consent",
                name: "declaration_of_consent",
                component: () => import("@/views/terms/declaration-of-consent.vue"),
                requiresAuth: false,
            },
            {
                path: "/eula",
                name: "eula",
                component: () => import("@/views/terms/eula.vue"),
                requiresAuth: false,
            },
            {
                path: "/privacy-policy",
                name: "privacy policy",
                component: () => import("@/views/terms/privacy.vue"),
                requiresAuth: false,
            },
            {
                path: "/zuluranking",
                name: "zuluranking",
                component: () => import("@/views/terms/zuluranking.vue"),
                requiresAuth: false,
            },
            {
                path: "/risk-disclaimer",
                name: "risk disclaimer",
                component: () => import("@/views/terms/risk-disclaimer.vue"),
                requiresAuth: false,
            },
            {
                path: "/terms-of-service-eu",
                name: "terms of service eu",
                component: () => import("@/views/terms/terms-of-service-eu.vue"),
                requiresAuth: false,
            },
            {
                path: "/copy-trading",
                name: "copy trading",
                component: () => import("@/views/trader/trading/copy_trading.vue"),
                requiresAuth: false,
            },
            // {
            //     path: "/not-found",
            //     name: "page not found",
            //     component: () => import("@/views/page_not_found/not-found.vue"),
            //     requiresAuth: false,
            // },
            {
                path: "/open-new-investror-account",
                name: "open new investror account",
                component: () => import("@/views/investment/open-new-investror-account.vue"),
                requiresAuth: false,
            },
            {
                path: "/printable-terms",
                name: "printable terms",
                component: () => import("@/views/terms/printable-terms.vue"),
                requiresAuth: false,
            },
            // {
            //     path: "/open-new-investror-account",
            //     name: "open new investror account",
            //     component: () => import("@/views/investment/open-new-investror-account.vue"),
            //     requiresAuth: false,
            // },
            {
                path: "/select-broker",
                name: "select broker",
                component: () => import("@/views/broker/select-broker.vue"),
                requiresAuth: false,
            },
            // {
            //     path: "/signup-activation",
            //     name: "signup activation",
            //     component: () => import("@/views/auth/signup-activation.vue"),
            //     requiresAuth: false,
            // },
            {
                path: "/social-trading",
                name: "social trading",
                component: () => import("@/views/social-feed/social-trading.vue"),
                requiresAuth: false,
            },
            {
                path: "/traders/list/:id",
                name: "view_all_leader",
                component: () => import("@/views/trader/view_all_leader.vue"),
                requiresAuth: false,
            },
            {
                path: "/all-market/:currency",
                name: "all_market",
                component: () => import("@/views/markets/all-market.vue"),
                requiresAuth: false,
            },
            // {
            //     path: "/page-not-found",
            //     name: "pagenotfound",
            //     component: () => import("@/components/shared/page-not-found.vue"),
            //     requiresAuth: false,
            // },
            {
                path: "/payment/success",
                name: "success",
                component: () => import("@/views/dashboard-new/pages/redirect/success.vue"),
                requiresAuth: true,
            },

            {
                path: "/payment/failure",
                name: "failure",
                component: () => import("@/views/dashboard-new/pages/redirect/failure.vue"),
                requiresAuth: true,
            },
            {
                path: "/rewards",
                name: "rewards",
                component: () => import("@/views/dashboard-new/pages/rewards.vue"),
                requiresAuth: true,
            },
            // {
            //     path: "/notifications",
            //     name: "notifications",
            //     component: () => import("@/views/dashboard-new/pages/notifications.vue"),
            //     requiresAuth: false,
            // },
            {
                path: "/notifications",
                name: "allNotifications",
                component: () => import("@/views/notifications/all-notifications.vue"),
                requiresAuth: false,
            },
            {
                path: "/watchlist",
                name: "watchlist",
                component: () => import("@/views/trader/watchlist/watchlist.vue"),
                requiresAuth: true,
            },
            {
                path: "/dashboard",
                name: "dashboard_portfolio",
                component: () => import("@/views/dashboard-new/dashboard_portfolio.vue"),
                requiresAuth: true,
            },
            {
                path: "/zuluguard-guide",
                name: "zuluguardGuide",
                component: () => import("@/views/zuluguard/zuluguard.vue"),
                requiresAuth: false,
            },
            // {
            //     path: "/capital-protection-info",
            //     name: "capitalProtectionInfo",
            //     component: () => import("@/views/zuluguard/capital-protection-info.vue"),
            //     requiresAuth: false,
            // },
            {
                path: "/trading-tools/pip-calculator",
                name: "pipCalculator",
                component: () => import("@/views/markets/calculator/pip.vue"),
                requiresAuth: false,
            },
            {
                path: "/trading-tools/margin-calculator",
                name: "marginCalculator",
                component: () => import("@/views/markets/calculator/margin.vue"),
                requiresAuth: false,
            },
            {
                path: "/trading-tools/profit-calculator",
                name: "profitCalculator",
                component: () => import("@/views/markets/calculator/profit.vue"),
                requiresAuth: false,
            },
            {
                path: "/trading-tools/currency-calculator",
                name: "currencyCalculator",
                component: () => import("@/views/markets/calculator/currency.vue"),
                requiresAuth: false,
            },
            {
                path: "/trading-tools",
                name: "calculator",
                component: () => import("@/views/markets/forex-tools.vue"),
                requiresAuth: false,
            },
            // New Funded
            {
                path: "/funded-account/funded-account",
                name: "fundedAccount",
                component: () => import("@/views/funded-account/funded-account.vue"),
                requiresAuth: false,
            },
            {
                path: "",
                name: "dashboardTabs",
                component: () => import("@/views/dashboard-new/dashboard.vue"),
                children: [
                    {
                        path: "/dashboard/personal-info",
                        name: "dashboardinfo",
                        component: () => import("@/views/dashboard-new/pages/personal-detail.vue"),
                        requiresAuth: true,
                    },
                    {
                        path: "/dashboard/trading-accounts",
                        name: "tradingAccounts",
                        component: () => import("@/views/dashboard-new/pages/trading-accounts.vue"),
                        requiresAuth: true,
                    },
                    {
                        path: "/dashboard/subscription",
                        name: "subscription",
                        component: () => import("@/views/dashboard-new/pages/subscription-plan.vue"),
                        requiresAuth: true,
                    },
                    {
                        path: "/dashboard/account",
                        name: "accountSetting",
                        component: () => import("@/views/dashboard-new/pages/account-settings.vue"),
                        requiresAuth: true,
                    },
                    {
                        path: "/dashboard/need-help",
                        name: "needHelp",
                        component: () => import("@/views/dashboard-new/pages/need-help.vue"),
                        requiresAuth: true,
                    },
                    {
                        path: "/dashboard/my-posts",
                        name: "myPosts",
                        component: () => import("@/views/dashboard-new/pages/my-feed.vue"),
                        requiresAuth: true,
                    },
                    {
                        path: "/dashboard/change-password",
                        name: "changePassword",
                        component: () => import("@/views/dashboard-new/pages/change-password.vue"),
                        requiresAuth: true,
                    },
                    {
                        path: "/dashboard/leader-widget",
                        name: "leaderWidget",
                        component: () => import("@/views/dashboard-new/pages/leader-widget.vue"),
                        requiresAuth: true,
                    },
                    {
                        path: "/dashboard/notifications",
                        name: "notificationSetting",
                        component: () => import("@/views/dashboard-new/pages/settings.vue"),
                        requiresAuth: true,
                    },
                    {
                        path: "/dashboard/trade-history",
                        name: "tradeHistory",
                        component: () => import("@/views/dashboard-new/pages/trade-history.vue"),
                        requiresAuth: true,
                    },
                ],
            },
            {
                path: "/blog/category/:name",
                name: "AllBlogs",
                component: () => import("@/views/blog/blog-category.vue"),
                requiresAuth: false,
            },
            {
                path: "/blog/post/:title",
                name: "blogDetail",
                component: () => import("@/views/blog/blog-detail.vue"),
                requiresAuth: false,
            },
            {
                path: "/sitemap",
                name: "sitemap",
                component: () => import("@/views/learn/sitemap.vue"),
                requiresAuth: false,
            },
            {
                path: "/welcome-a",
                name: "welcomeA",
                component: () => import("@/views/welcomepage/welcome-a.vue"),
                requiresAuth: false,
            },
            {
                path: "/welcome-b",
                name: "welcomeB",
                component: () => import("@/views/welcomepage/welcome-b.vue"),
                requiresAuth: false,
            },
            {
                path: "/welcome-c",
                name: "welcomeC",
                component: () => import("@/views/welcomepage/welcome-c.vue"),
                requiresAuth: false,
            },
            {
                path: "/trading-signals",
                name: "tradingSignals",
                component: () => import("@/views/trader/trading-signals.vue"),
                requiresAuth: false,
            },
            {
                path: "/forex-trading",
                name: "forexTrading",
                component: () => import("@/views/trader/forex-trading.vue"),
                requiresAuth: false,
            },
        ],
    },
];

/* Create sitemap.XML */
// routes.map((site)=>{
//     if(site.children){
//         site.children.map((siteChild)=>{
//             console.log(`<url><loc>https://www.zulutrade.com${siteChild.path}</loc></url>`)
//         })
//     }
//     console.log(`<url><loc>https://www.zulutrade.com${site.path}</loc></url>`)
// })

const router = createRouter({
    base: process.env.NODE_ENV === "production" ? "/" : "/",
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) { 
        if(to.name != from.name){
            document.getElementById("app").scrollIntoView({ behavior: "smooth" });
        } else {
            return savedPosition
        }
    },
});
const haveLiveAccount = () => {
    try{
        const store = myStore();
        if(store.user.access_token && store.userTradingAccountsList.length){
            let data = store.userTradingAccountsList.filter( i=> i.demo === false);
            if(data.length){
                return true
            }else{
                return false
            }
        }else{  
            return false
        }
    }catch(e){
        return false
    }
}

router.beforeEach((to) => {
    const store = myStore();
    const badge = document.getElementsByClassName('grecaptcha-badge');
    if (badge.length) {
        badge[0].style.visibility = (to.path == '/login' || to.name == 'dashboardinfo') ? 'visible' : 'hidden';
    }
    if(to.name=='register' || to.name=='widgets' || to.name=='banner' || to.name == 'traderInfo'){
        document.querySelector('body').classList.add("hideChatPage");
         document.querySelector('body').classList.add("affiliate");
    } else {
        document.querySelector('body').classList.remove("hideChatPage");
         document.querySelector('body').classList.remove("affiliate");
    }
    //==============Redirection URl for 1.0 ===============================//
    // if(to.query.ref){
    //     let ref = to.query.ref
    // }
    if(to.path == '/sign-up-investor' || to.path == '/sign-up-trader-program' || to.path == '/create-account'){
        let path = `${static_vars.domainURL}register`;
        if(Object.keys(to.query).length){
            let qs = new URLSearchParams(to.query);
            path += `?${qs.toString()}`;
        }
        window.open(path,'_self')
    }else if(to.fullPath == '/user#portfolio' || to.fullPath == '/user#portfolio/automator' || to.fullPath == '/user#pending-orders' || to.fullPath == '/user#trade-history/cancelled'){
        if(store.user.access_token){
            return { path: '/dashboard' }
        }else{
            return { path: '/' }
        }
    }else if(to.fullPath == '/user#feed'){
        return {path : '/social-feed'}
    }else if(to.path == '/sign-up-affiliate-program'){
        window.open(`https://www.zulutrade.com/partners/`,'_self')
    }else if(to.path == '/forgot-password '){
        window.open(`${static_vars.domainURL}register/forgot-password`,'_self')
    }else if(to.path == '/update-password '){
        if(store.user.access_token){
            return { path: '/dashboard/change-password' }
        }else{
            return { path: '/' }
        }
    }else if(to.path == '/notifications'){
        if(!store.user.access_token){
            return { path: '/' }
        }
    }else if(to.path == '/trade-details'){
        return { path: '/' }
    }else if(to.path == '/disclaimer' || to.path == '/mobile/risk-disclaimer'){
        return { path: '/risk-disclaimer' }
    }else if(to.path == '/privacy'){
        return { path: '/privacy-policy' }
    }else if(to.path == '/mobile/faq' || to.path == '/mobile/support' || to.path == '/avoid-hedging-guide' || to.path == '/capital-protection-info'){
        if(store.user.access_token){
            return { path: '/dashboard/need-help' }
        }else{
            return { path: '/' }
        }
    }else if(to.path == '/trading-guides' || to.path == '/eu-guide' || to.path == '/platforms' || to.path == '/combos' || to.path == '/combos-plus' || to.path == '/combos-plus' || to.path == '/cryptos' || to.path == '/zuluscript-guide'){//to.path == '/welcome-a' || to.path == '/welcome-b' || to.path == '/welcome-c' || 
        return { path: '/' }
    }else if((to.path == '/social-forex-trading') || (to.path == '/stock-trading') || (to.path == '/cryptocurrency-trading') || (to.path == '/commodities-trading') || (to.path == '/index-trading')){
        return {path : '/markets'}
    }else if(to.path == '/trader-guide'){
        return {path : '/leader-guide'}
    }/*else if(to.path == '/zuluguard-guide'){
        return {path : '/autoprotect-your-account'}
    }*/else if(to.path == '/list'){
        return {path : '/watchlist'}
    }else if(to.path == 'simulate'){
        return {path : '/simulation'}
    } else if(to.path == '/settings'){
        return {path : '/dashboard/personal-info'}
    }else if(to.path == '/open-new-investror-account'){
        return {path : '/select-broker'}
    }

    //====================END===============================================//        
    //when we hit path with token
    if(to.query.token){
        store.reset()
        if(store.user.access_token){
            let data = store.user
            data.access_token = to.query.token
            if(to.query.refresh_token){
                store.$patch({'user' : {'access_token' : to.query.token,'refresh_token' : to.query.refresh_token}})
            }else{
                store.$patch({'user' : {'access_token' : to.query.token}})
            }
        }else{
            if(to.query.refresh_token){
                store.$patch({'user' : {'access_token' : to.query.token,'refresh_token' : to.query.refresh_token}})
            }else{
                store.$patch({'user' : {'access_token' : to.query.token}})
            }
        }
        if (localStorage.getItem("zulutoken") !== null) {
            localStorage.removeItem("zulutoken");
           let json = {};
            if (localStorage.getItem("zulurefreshToken") !== null) {
                localStorage.removeItem("zulurefreshToken");
                if(to.query.refresh_token){
                    json["refresh_token"] = to.query.refresh_token;
                }
            }
           json['access_token'] = to.query.token
            localStorage.setItem('zulutoken', to.query.token);
            if(to.query.refresh_token){
                localStorage.setItem('zulurefreshToken', to.query.refresh_token);
            }
            // store.$patch({'user' : json})
        }else{
          localStorage.setItem('zulutoken', to.query.token);
          if(to.query.refresh_token){
            localStorage.setItem('zulurefreshToken', to.query.refresh_token);
          }
        }
        if(to.query.redirect_to){
            if(to.query.target=='mobile'){
                router.push({name : to.query.redirect_to, query: {target:'mobile'}})
            } else {
                router.push({name : to.query.redirect_to})
            }
        } else {
            router.replace(to.path)
        }
    }

    // Theme(Dark || Light) mode in widget's
    if(to.query.theme){
        localStorage.setItem('theme',to.query.theme)
        document.documentElement.setAttribute('data-theme', to.query.theme)
    }

    // if (to.path == '/') return { name: 'index' }
    if (to.path == '/become-a-trader') return { path: '/become-a-leader' }
    if (to.path == '/support') return { path: '/help-center' }
    if (to.path == '/careers') return { path: '/about' }
    if (to.path == '/forex-tools') return { path: '/trading-tools' }
    if (to.path == '/forex-tools/pip-calculator') return { path: '/trading-tools/pip-calculator' }  
    if (to.path == '/forex-tools/profit-calculator') return { path: '/trading-tools/profit-calculator' }    
    if (to.path == '/traders') return { path: '/leaders' }
    if (to.path == '/rates') return { path: '/markets' }
    if (to.path == '/select-broker') return { path: '/' }
    if (to.meta.requiresAuth && !store.user.access_token && (to.name == "dashboardinfo" || to.name == "tradingAccounts" || to.name == "subscription" || to.name == "feedBack")) return { path: "/" };
    if (to.meta.requiresAuth && !store.user.access_token && (to.name == "dashboard_portfolio")) return { path: "/" };
    if (to.meta.requiresAuth && store.user.access_token && !store.customerDetail.completed && (to.name == "tradingAccounts" || to.name == "subscription")) return { path: "/dashboard/personal-info" };
    if (
        store.user.access_token &&
        (!store.customerDetail.completed || !haveLiveAccount() || store.customerDetail.requiredActions)&&
        to.name == "subscription"
    )
        return { path: "/dashboard/personal-info" };
        // return true

    if (to.path == '/login' && store.user.access_token) return { path: '/dashboard' }
    if (to.path == '/dashboard/leader-widget'){
        if(store.user.access_token){
            // Object.keys(store.customerDetail).length && Object.keys(store.customerDetail.accounts).length && store.customerDetail.accounts.length && 
            if(!store.isLeaderList.length){
                return { path: '/' }
            }
        } else {
            return { path: '/' }
        }
    } 
});
export default router;
